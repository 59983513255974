import { Global } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/styles";
import React from "react";
import "../App.css";
import Logo from "../assets/logo.png";
import Box from "@mui/material/Box";
import { Badge, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(27,25,24, 0.97)",
}));

const drawerBleeding = 36;
export function SideMenu({ open, toggleDrawer, container, user, count, nftCount }) {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };
  console.log(JSON.parse(JSON.stringify(user ? user : null)));
  user = JSON.parse(JSON.stringify(user ? user : null));

  return (
    <div>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(100%)`,
            width: `calc(50%)`,
            maxWidth: "400pt",
            overflow: "clip",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: false,
        }}
      >
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Button
              onClick={() => {
                routeChange("/myprofile");
                toggleDrawer(false);
              }}
              style={{
                marginTop: "100pt",
              }}
            >
              <img
                src={
                  user?.image !== "default"
                    ? user?.image
                      ? user?.image
                      : Logo
                    : Logo
                }
                height="100pt"
                width={user?.image !== "default" ? "100pt" : "140pt"}
                style={{
                  borderRadius: user?.image !== "default" ? "50%" : "",
                  objectFit: "cover",
                }}
              />
            </Button>{" "}
            <Badge
              badgeContent={user && count}
              color="success"
              style={{ color: "white" }}
            >
              {" "}
              <Button
                onClick={() => {
                  routeChange("/myprofile");
                  toggleDrawer(false);
                }}
                variant="outlined"
                style={{
                  textTransform: "none",
                  color: "white",
                  borderColor: "white",
                }}
                fullWidth
              >
                <Typography style={{ fontSize: "small" }}>
                  Profile & Stats
                </Typography>
              </Button>
            </Badge>
            <Button
              onClick={() => {
                routeChange("/");
                toggleDrawer(false);
              }}
              style={{
                marginTop: "40pt",
              }}
            >
              <Typography
                style={{
                  fontSize: "large",
                  color: "#eb5454",
                  fontFamily: "Bebas Neue, cursive",
                }}
              >
                Home
              </Typography>
            </Button>
            <Button
              onClick={() => {
                routeChange("/hitlist");
                toggleDrawer(false);
              }}
              style={{
                marginTop: "20pt",
              }}
            >
              <Typography
                style={{
                  fontSize: "large",
                  color: "#eb5454",
                  fontFamily: "Bebas Neue, cursive",
                }}
              >
                The Hit List
              </Typography>
            </Button>
            <Button
              onClick={() => {
                routeChange("/journey");
                toggleDrawer(false);
              }}
              style={{
                marginTop: "20pt",
              }}
            >
              <Badge
                badgeContent={
                  nftCount
                }
                color="success"
                style={{ color: "white" }}
              >
                <Typography
                  style={{
                    fontSize: "large",
                    color: "#eb5454",
                    fontFamily: "Bebas Neue, cursive",
                  }}
                >
                  NFT
                </Typography>
              </Badge>
            </Button>
            <Button
              onClick={() => {
                routeChange("/leaderboard");
                toggleDrawer(false);
              }}
              style={{
                marginTop: "20pt",
              }}
            >
              <Typography
                style={{
                  fontSize: "large",
                  color: "#eb5454",
                  fontFamily: "Bebas Neue, cursive",
                }}
              >
                Leaderboard
              </Typography>
            </Button>
            <Button
              onClick={() => {
                toggleDrawer(false);
                routeChange("/raffle");
              }}
              style={{
                marginTop: "20pt",
              }}
            >
              <Typography
                style={{
                  fontSize: "large",
                  color: "#eb5454",
                  fontFamily: "Bebas Neue, cursive",
                }}
              >
                Raffle
              </Typography>
            </Button>
          </Grid>
        </StyledBox>
      </SwipeableDrawer>
    </div>
  );
}
