import { Card, Container, Grid, Typography } from "@mui/material";
import Moralis from "moralis";
import { useState } from "react";

export function MemberHistoryView({
  user,
  getAchievementHistory,
  achievementsCompleted,
}) {
  const primaryColor = "white";
  const secondaryColor = "gold";
  const [achievementsLoaded, setAchievementsLoaded] = useState(false);

  if (!achievementsLoaded) {
    setAchievementsLoaded(true);
    getAchievementHistory();
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      justifySelf="center"
      style={{
        margin: "auto",
        width: "90%",
        marginBottom: "50pt",
        background: "linear-gradient(120deg, #fa4616, #0021a5)",
        borderRadius: "5pt",
      }}
    >
      <Typography
        style={{
          fontSize: "xx-large",
          marginTop: "20pt",
          overflow: "ellipsis",
          width: "100%",
        }}
      >
        {user.customUsername ? user.customUsername : "Anon"}'s Point History
      </Typography>
      <div
        style={{
          maxHeight: "60vh",
          overflowX: "scroll",
        }}
      >
        {achievementsCompleted.length > 0 ? (
          achievementsCompleted.map((achievement, index) => (
            <Card
              key={achievement.objectId + index}
              style={{
                background: JSON.parse(JSON.stringify(achievement)).bonus
                  ? "linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)"
                  : primaryColor,
                margin: "5pt",
                paddingBottom: "3pt",
                paddingTop: "2pt",
                paddingRight: "2pt",
                width: "90%",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography style={{ fontWeight: "bold" }}>
                  {achievement.name}
                </Typography>
                <Typography style={{ fontSize: "small" }}>
                  {" (" +
                    achievement.points +
                    "pt" +
                    (achievement.points === "1" ? "" : "s") +
                    ")"}
                </Typography>
              </Grid>
              <Typography
                style={{
                  fontSize: "x-small",
                  fontWeight: "bold",
                  marginTop: "9pt",
                }}
              >
                {new Date(achievement.dateCompleted).toLocaleString()}
              </Typography>
            </Card>
          ))
        ) : (
          <Typography>
            No Points History for{" "}
            {user.customUsername ? user.customUsername : user.username}
          </Typography>
        )}
      </div>
    </Grid>
  );
}
