import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowRight,
  Menu,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Moralis from "moralis";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useViewport } from "../viewport";
import { SectionHeader } from "./SectionHeader";
import metro from "../assets/MetroPhoto8.jpg";
import { ForumDetailTile } from "./ForumDetailTile";
import MetroBaseball from "../assets/MetroBaseball.png";

export function ForumView({ user, customUsername, toggleDrawer, count }) {
  let navigate = useNavigate();
  const [forumLoaded, setForumLoaded] = useState(false);
  const routeChange = (path) => {
    navigate(path);
  };
  const { width } = useViewport();
  const breakpoint = 620;
  const [forumCategories, setForumCategories] = useState([
    {
      threadIcon: "💲",
      threadName: "MetroFlex Trainers",
      threadDescription:
        "Promote Your Business, Services, Programs, Bootcamps, and More Here",
      threadComments: [],
    },
    {
      threadIcon: "📅",
      threadName: "MetroFlex Events",
      threadDescription:
        "U.S.P.A, Fight Nights, Wrestling— Stay Up To Date Here.",
      threadComments: [],
    },
    {
      threadIcon: "🎟️",
      threadName: "Raffles",
      threadDescription: "Find all the upcoming raffles.",
      threadComments: [],
    },
  ]);

  const getForumFeed = async () => {
    setForumLoaded(true);
    const res = await Moralis.Cloud.run("getForum");
    console.log(res);
    const newObj = [
      {
        threadIcon: "💲",
        threadName: "MetroFlex Trainers",
        threadDescription:
          "Promote Your Business, Services, Programs, Bootcamps, and More Here",
        threadComments: [],
      },
      {
        threadIcon: "📅",
        threadName: "MetroFlex Events",
        threadDescription:
          "U.S.P.A, Fight Nights, Wrestling— Stay Up To Date Here.",
        threadComments: [],
      },
      {
        threadIcon: "🎟️",
        threadName: "Raffles",
        threadDescription: "Find all the upcoming raffles.",
        threadComments: [],
      },
    ];
    for (var i = 0; i < res.length; i++) {
      var categoryIndex = -1;
      for (var x = 0; x < forumCategories.length; x++) {
        if (forumCategories[x].threadName === res[i].topic) {
          categoryIndex = x;
          break;
        }
      }
      if (categoryIndex !== -1) {
        newObj[categoryIndex].threadComments.push(res[i]);
      }
    }
    setForumCategories(newObj);
  };

  if (!forumLoaded) {
    getForumFeed();
  }
  console.log(forumCategories);
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        height: "100%",
        width: "100vw",
        maxWidth: "800pt",
        paddingBottom: "100pt",
      }}
    >
      <Grid
        item
        width="100%"
        style={{
          paddingTop: "10pt",
          paddingLeft: "10pt",
          maxWidth: "800pt",
          position: "fixed",
          background: "rgba(27,25,24,0.97)",
          zIndex: 2,
        }}
      >
        <Grid container direction="row" justifyItems="space-between">
          <Badge badgeContent={count} variant="standard" color="success">
            <IconButton onClick={toggleDrawer(true)} style={{ color: "white" }}>
              <Menu style={{ color: "white" }} />
            </IconButton>
          </Badge>{" "}
          <Grid item style={{ margin: "auto" }}>
            <a href={"/"}>
              <img
                src={MetroBaseball}
                style={{ maxWidth: "100%", maxHeight: "40pt" }}
              />
            </a>
          </Grid>
          <Grid item style={{ margin: "10pt" }}>
            <IconButton>
              <Menu style={{ color: "transparent" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        style={{
          marginTop: "50pt",
          fontFamily: "Road_Rage",
          fontSize: "2em",
          marginBottom: "10pt",
          color: "#eb5454",
        }}
      >
        Forum
      </Typography>
      <img
        src={metro}
        width="100%"
        style={{
          maxWidth: "400pt",
          maxHeight: "150pt",
          objectFit: "cover",
          borderRadius: "10pt",
          boxShadow: "0pt 0pt 3pt #eb5454",
          marginBottom: "10pt",
        }}
      />
      <Typography
        style={{
          color: "white",
          fontFamily: "Roboto, sans-serif",
          marginBottom: "10pt",
          maxWidth: "550pt",
          fontSize: width > breakpoint ? "large" : "1.1em",
          marginRight: "10pt",
          marginLeft: "10pt",
        }}
      >
        Exercise your right to Free Speech.
        <br />
        God Bless America
      </Typography>
      <Grid container direction="column" style={{ padding: "10pt" }}>
        {forumCategories.map((activity) => {
          return (
            <Box
              border={1}
              style={{
                borderColor: "white",
                borderRadius: "5pt",
                width: "100%",
                maxWidth: "500pt",
                margin: "auto",
                marginBottom: "10pt",
                padding: "10pt",
              }}
            >
              <Button
                key={activity.threadName + "Button"}
                onClick={(event) => {
                  event.preventDefault();
                  routeChange(
                    "/forum/" + activity.threadName.replace(/\s+/g, "_")
                  );
                }}
                style={{ color: "white", textTransform: "none" }}
              >
                <Grid container direction="column">
                  <Grid
                    container
                    key={activity?.threadName}
                    direction="row"
                    justifyContent={"space-between"}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Grid
                        container
                        key={activity?.threadName}
                        direction="row"
                        justifyContent={"space-between"}
                        wrap="nowrap"
                      >
                        <Typography
                          style={{
                            color: "white",
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: "bold",
                            marginBottom: "5pt",
                            maxWidth: "400pt",
                            fontSize: "x-large",
                          }}
                          align="left"
                        >
                          {activity?.threadIcon}
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontFamily: "Roboto, sans-serif",
                            fontWeight: "bold",
                            marginBottom: "5pt",
                            maxWidth: "400pt",
                            fontSize: "x-large",
                          }}
                          align="left"
                        >
                          {activity?.threadName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <IconButton
                      onClick={() => {
                        // setOpen(true);
                      }}
                    >
                      <KeyboardDoubleArrowRight style={{ color: "white" }} />
                    </IconButton>
                  </Grid>
                  <Typography
                    style={{
                      color: "white",
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: "lighter",
                      marginBottom: "10pt",
                      maxWidth: "400pt",
                      fontSize: "medium",
                    }}
                    align="left"
                  >
                    {activity?.threadDescription}{" "}
                  </Typography>
                </Grid>
              </Button>
              {activity?.threadComments?.length > 0 ? (
                <Grid container direction="column">
                  {activity?.threadComments.map((post) => {
                    console.log(post);
                    return (
                      <ForumDetailTile
                        key={post.id}
                        post={post}
                        currentUser={user}
                        getForumFeed={getForumFeed}
                        forumName={activity?.threadName}
                      />
                    );
                  })}
                </Grid>
              ) : (
                <Typography>No posts.</Typography>
              )}
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
}
