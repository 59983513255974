import { Box, Button, Container, Grid, Icon, Typography } from "@mui/material";
import React from "react";
import { useViewport } from "../viewport";
import "../App.css";
import AppleIcon from "../assets/apple-app-store.png";
import GoogleIcon from "../assets/google-play.png";
import { Apple, Google, Shop } from "@mui/icons-material";
import Logo from "../assets/logo.png";
import appStore from "../assets/appstore.svg";

export function ConnectHelp({handleOpen}) {
  const { width } = useViewport();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      style={{
        overflow: "hidden",
        background: "rgba(27,25,24,0.97)",
      }}
    >
      <Box
        border={1}
        style={{
          width: "40%",
          maxWidth: "110pt",
          borderRadius: "10pt",
          margin: "5pt",
          padding: "3pt",
          borderColor: "#eb5454",
        }}
      >
        <Typography
          style={{
            fontSize: "medium",
            color: "white",
            fontWeight: "bold",
            marginBottom: "5pt",
            marginTop: "5pt",
          }}
        >
          Coinbase Wallet
        </Typography>
        <Grid container direction="row" justifyContent="center">
          <a
            href="https://apps.apple.com/us/app/coinbase-wallet-store-crypto/id1278383455"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <img src={appStore} width="100%"></img>
          </a>
          {/* <a href="https://play.google.com/store/apps/details?id=org.toshi&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Get it on Google Play"
              width="100%"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a> */}
        </Grid>
      </Box>
      <Typography>Or</Typography>
      <Box
        border={1}
        style={{
          width: "40%",
          maxWidth: "110pt",
          borderRadius: "10pt",
          margin: "5pt",
          padding: "3pt",
          borderColor: "#eb5454",
        }}
      >
        <Typography
          style={{
            fontSize: "medium",
            color: "white",
            fontWeight: "bold",
            marginTop: "5pt",
          }}
        >
          Username
        </Typography>
        <Button
        onClick={handleOpen}
          variant="contained"
          style={{
            background: "white",
            color: "black",
            fontSize: "medium",
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Login
        </Button>
      </Box>
    </Grid>
  );
}
