import { Menu } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import MetroBaseball from "../assets/MetroBaseball.png";

export function TopNavBar({toggleDrawer}){
    return <Grid container direction="row" justifyItems="space-between">
    <Grid item style={{ margin: "10pt" }}>
      <IconButton onClick={toggleDrawer(true)}>
        <Menu style={{ color: "white" }} />
      </IconButton>
    </Grid>
    <Grid item style={{ margin: "auto" }}>
      <a href={"/"}>
        <img
          src={MetroBaseball}
          style={{ maxWidth: "100%", maxHeight: "40pt" }}
        />
      </a>
    </Grid>
    <Grid item style={{ margin: "10pt" }}>
      <IconButton>
        <Menu style={{ color: "transparent" }} />
      </IconButton>
    </Grid>
  </Grid>
}