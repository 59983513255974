import {
  Cancel,
  CheckBoxSharp,
  CheckCircleOutline,
  ExitToApp,
  Menu,
  QrCode,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  Modal,
  Typography,
} from "@mui/material";
import { Box, mergeBreakpointsInOrder } from "@mui/system";
import Moralis from "moralis";
import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ReactTypingEffect from "react-typing-effect";
import "../App.css";
import Logo from "../assets/logo.png";
import { useViewport } from "../viewport";
import { ConnectHelp } from "./ConnectHelp";
import Wallet from "../assets/wallet.png";
import Ad from "../assets/Manly_Event-5.png";
import { styled } from "@mui/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import promo from "../assets/NFT_Promp.png";
import MetroBaseball from "../assets/MetroBaseball.png";
import demo from "../assets/iphoneMock0.png";
import demo2 from "../assets/iphoneMock1.png";
import demo3 from "../assets/iphoneMock3.png";
import demo4 from "../assets/iphoneMock4.png";
import demo5 from "../assets/iphoneMock5.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper";
import { LandingPage } from "./LandingPage";
import { MainDrawerToggle } from "./MainDrawerToggle";
import { BarcodeScan } from "./BarcodeScan";
import { SectionHeader } from "./SectionHeader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#22222",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "5pt",
  p: 4,
};

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(27,25,24, 0.97)",
}));

export function MainHeader({
  user,
  ethAddress,
  points,
  membershipId,
  customUsername,
  toggleDrawer,
  login,
  count,
}) {
  const { width } = useViewport();
  const breakpoint = 500;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [modal, setOpen2] = React.useState(false);
  const handleClose2 = () => setOpen2(false);
  const [membership, setMembership] = useState();
  const [input, setInput] = useState();
  const [newUsername, setNewUsername] = useState();
  const [cUsername, setCustomUsername] = useState();
  console.log(membership);
  console.log(membershipId);
  console.log(points);
  async function reload() {
    console.log("triggered reload");
    await login();
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{
        background: "rgba(27,25,24,255)",
      }}
    >
      <Grid
        item
        style={{
          width: "100%",
          paddingLeft: "10pt",
          paddingTop: "10pt",
          paddingBottom: "10pt",
          position: "fixed",
          background: "rgba(27,25,24,255)",
          zIndex: 2,
        }}
      >
        <Grid container direction="row" justifyItems="space-between">
          <Grid item style={{ margin: "10pt" }}>
            <Badge badgeContent={count} variant="standard" color="success">
              <IconButton onClick={toggleDrawer(true)}>
                <Menu style={{ color: "white" }} />
              </IconButton>
            </Badge>
          </Grid>
          <Grid item style={{ margin: "auto" }}>
            <a href={"/"}>
              <img
                src={MetroBaseball}
                style={{ maxWidth: "100%", maxHeight: "40pt" }}
              />
            </a>
          </Grid>
          <Grid item style={{ margin: "10pt" }}>
            <IconButton>
              <Menu style={{ color: "transparent" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Swiper
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        autoplay={false}
      >
        <SwiperSlide style={{ maxWidth: "100vw" }}>
          <LandingPage
            user={user}
            customUsername={customUsername}
            setMembership={setMembership}
            ethAddress={ethAddress}
            login={() => reload()}
            bonusCount={count}
          />
        </SwiperSlide>
        <SwiperSlide style={{ maxWidth: "100vw", alignItems: "flex-start" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            justifyItems="center"
            style={{ margin: "auto", paddingTop: "100pt" }}
          >
            <Typography
              style={{
                fontSize: "1.8em",
                color: "#eb5454",
                fontFamily: "Road_Rage",
                margin: "auto",
                marginBottom: "5pt",
              }}
              textAlign="center"
            >
              EXCLUSIVE DIGITAL MURAL
            </Typography>
            <Typography
              style={{
                color: "white",
                fontSize: "1em",
                fontFamily: "Requiem, sans-serif",
                marginBottom: "10pt",
                maxWidth: "250pt",
              }}
              textAlign="center"
            >
              Collect the mural by redeeming your reward points!
            </Typography>
            <img
              src={promo}
              width="100%"
              style={{
                maxWidth: "250pt",
                margin: "auto",
                boxShadow: "0px 0px 25px white",
                borderRadius: "10pt",
              }}
            />
          </Grid>
        </SwiperSlide>
        <SwiperSlide style={{ maxWidth: "100vw", alignItems: "flex-start" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            justifyItems="center"
            style={{ margin: "auto", padding: "100pt" }}
          >
            <Grid item>
              <SectionHeader title={"DAILY WORKOUTS"} />
            </Grid>
            <Grid item>
              <img
                src={demo}
                width=""
                style={{ maxWidth: "300pt", maxHeight: "300pt" }}
              />
            </Grid>
          </Grid>
        </SwiperSlide>
        <SwiperSlide style={{ maxWidth: "100vw", alignItems: "flex-start" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            justifyItems="center"
            style={{ margin: "auto", padding: "100pt" }}
          >
            <SectionHeader title={"Climb the leaderboard"} />
            <img
              src={demo4}
              style={{ maxWidth: "300pt", maxHeight: "300pt" }}
            />
          </Grid>
        </SwiperSlide>{" "}
        <SwiperSlide style={{ maxWidth: "100vw", alignItems: "flex-start" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
            justifyItems="center"
            style={{ margin: "auto", padding: "100pt" }}
          >
            <SectionHeader title={"Unlock the Mural"} />
            <img
              src={demo3}
              style={{ maxWidth: "300pt", maxHeight: "300pt" }}
            />
          </Grid>
        </SwiperSlide>
      </Swiper>
      {user && (
        <div>
          <BarcodeScan
            handleClose={handleClose2}
            modal={modal}
            selectedUser={user}
          />
          <Button
            style={{ textTransform: "none", color: "white" }}
            onClick={(event) => {
              setOpen2(true);
            }}
          >
            <Grid
              container
              direction="row"
              alignItems={"center"}
              justifyContent="center"
              wrap="nowrap"
              style={{
                position: "fixed",
                bottom: 10,
                right: 0,
                background: "#eb5454",
                fontFamily: "Roboto, sans-serif",
                borderTopLeftRadius: "30pt",
                borderBottomLeftRadius: "30pt",
                padding: "10pt",
                maxWidth: "120pt",
                zIndex: 1,
              }}
            >
              <QrCode
                style={{
                  color: "white",
                  border: "1pt dashed white",
                  borderRadius: "50%",
                  padding: "5pt",
                  height: "25pt",
                  width: "25pt",
                }}
              />
              <Grid container direction="column">
                <Typography
                  style={{
                    fontFamily: "Roboto, sans-serif",
                  }}
                  align="right"
                >
                  <b>{points?.toString()} Points</b>
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    maxLines: 1,
                  }}
                  align="right"
                >
                  Scan Card
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </div>
      )}
    </Grid>
  );
}
