import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Moralis from "moralis";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8u1pxMU9mngk8F2TB6uZQ4Zkavt-Qld0",
  authDomain: "metroflex-nft.firebaseapp.com",
  projectId: "metroflex-nft",
  storageBucket: "metroflex-nft.appspot.com",
  messagingSenderId: "211685706290",
  appId: "1:211685706290:web:150b14b219cd24e6f18a2b",
  measurementId: "G-WJWND6K410",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);/* Moralis init code */
const serverUrl = "https://df7f3ghoensc.usemoralis.com:2053/server";
const appId = "6GQoJjklMnsK2skhUTTa2EyGgvXl59gGB2mhzekZ";
Moralis.start({ serverUrl, appId });

/* TODO: Add Moralis Authentication code */

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
