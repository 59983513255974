import { Apple, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useViewport } from "../viewport";
import { SectionHeader } from "./SectionHeader";
import appStore from "../assets/appstore.svg";
import { useState } from "react";
import "./styles.css";

export function FAQView() {
  const { width } = useViewport();
  const [opened, setOpened] = useState([]);

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      style={{
        overflow: "clip",
        backgroundColor: "rgba(27,25,24, 0.97)",
        marginBottom: "50pt",
        maxWidth: "100vw",
      }}
    >
      <SectionHeader title={"faq"} />
      <AccordianBox
        width={width}
        question={"What is a NFT? (Non-Fungible Token)"}
        answer={
          "A secured digital asset that can be obtained to sell, buy, "+
          "or collect. Metroflex NFTs provide our members with "+
          "innovative ways to earn benefits that they can utilize with "+
          "their membership."
        }
      />
      <AccordianBox
        width={width}
        question={"What is a crypto wallet?"}
        answer={
          "A crypto wallet is an app that is compatible with blockchain "+
          "websites. Coinbase Wallet serves as a secure, reliable "+
          "wallet for users to interact with Crypto, NFTs, and the "+
          "Metroflex App."
        }
        links={true}
      />
      <AccordianBox
        width={width}
        question={"Why do I need a crypto wallet?"}
        answer={
          "Your wallet will serve as a destination where all earned "+
          "digital assets will be sent to. Obtained digital assets can "+
          "be stored, sold or sent; the choice is yours."
        }
      />
      <AccordianBox
        width={width}
        question={"How do I set up my crypto wallet?"}
        answer={
          "After opening the wallet app, click the middle tab to open the web " +
          'browser. Then, type in Metroflex.app in the search bar. Click "Okay"' +
          " on the pop up to sign up."
        }
      />
    </Grid>
  );
}

function AccordianBox({ width, question, answer, links }) {
  const [opened, setOpened] = useState(false);

  return (
    <Box
      border={1}
      style={{
        padding: "10pt",
        height: "100%",
        margin: "10pt",
        width: "90%",
        maxWidth: "500pt",
        borderRadius: "10pt",
        fontFamily: "Pangolin, cursive",
      }}
    >
      <TableContainer sx={{ maxHeight: "calc(100vh - 250px)" }}>
        <Table size="small" sx={{ minWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={
                  opened
                    ? () => {
                        setOpened(false);
                      }
                    : () => {}
                }
                style={{
                  color: "white",
                  fontSize: width > 700 ? "x-large" : "x-large",
                }}
              >
                {question}
                {opened && (
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: width > 700 ? "large" : "large",
                    }}
                  >
                    <KeyboardArrowUp />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
      >
            <TableRow>
              {!opened ? (
                <TableCell
                  onClick={() => {
                    setOpened(true);
                  }}
                  style={{
                    color: "white",
                    fontSize: width > 700 ? "large" : "large",
                  }}
                >
                  <KeyboardArrowDown />
                </TableCell>
              ) : (
                <TableCell
                  style={{
                    color: "white",
                    fontSize: width > 700 ? "large" : "large",
                  }} className='faq'
                >
                  {answer}
                  {links && <div> <br />
                    <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <a
                        href="https://apps.apple.com/us/app/coinbase-wallet-store-crypto/id1278383455"
                        target="_blank"
                        style={{
                          textTransform: "none",
                          textDecoration: "none",
                        }}
                      >
                        <img
                          alt="Download on app store"
                          src={appStore}
                          height="40pt"
                        ></img>
                      </a>
                      <a
                        target="_blank"
                        style={{
                          textTransform: "none",
                          textDecoration: "none",
                        }}
                        href="https://play.google.com/store/apps/details?id=org.toshi&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      >
                        <img
                          alt="Get it on Google Play"
                          height="55pt"
                          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                        />
                      </a>
                    </Grid></div>}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
