import {
  Container,
  Grid,
  IconButton,
  Modal,
  ModalUnstyled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useViewport } from "../viewport";
import { SectionHeader } from "./SectionHeader";
import "../App.css";
import { useState } from "react";
import METROFLEX from "../assets/NFT_Promp1.png";
import {
  Check,
  Close,
  Collections,
  Leaderboard,
  LocalActivity,
  Menu,
  WorkspacePremium,
} from "@mui/icons-material";
import DefaultAvatar from "../assets/defaultAvatar.png";
import DefaultAvatarF from "../assets/defaultAvatarF.png";
import styled from "@emotion/styled";
import { FAQView } from "./FAQ";
const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "black",
  p: 2,
  px: 4,
  pb: 3,
  pt: 8,
  color: "white",
  border: "2px solid #000",
};

export function FAQModal({ handleClose, modal }) {
  const { width } = useViewport();
  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={modal}
      onClose={handleClose}
      onBackdropClick={handleClose}
      BackdropComponent={Backdrop}
    >
      <Box sx={style} style={{ overflow: width < 600 ? "scroll" : "" }}>
        <Grid container direction="column" alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <IconButton onClick={handleClose}>
              <Close style={{ color: "black" }} />
            </IconButton>
            <IconButton onClick={handleClose}>
              <Close style={{ color: "white" }} />
            </IconButton>
          </Grid>
          <FAQView />
        </Grid>
      </Box>
    </StyledModal>
  );
}
