import { Badge, Box, Button, IconButton, Typography } from "@mui/material";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import METROFLEX from "../assets/NFT_Promp1.png";
import MyCanvas from "../assets/MyCanvas.gif";
import DefaultAvatar from "../assets/defaultAvatar.png";
import DefaultAvatarF from "../assets/defaultAvatarF.png";
import { SectionHeader } from "./SectionHeader";
import { useViewport } from "../viewport";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import Moralis from "moralis";
import { MainBody } from "./MainBody";
import { Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import fullrend from "../assets/NFT_Promp.png";
import metro from "../assets/MetroPhoto9.jpg";
import { FAQModal } from "./FAQModal";
import MetroBaseball from "../assets/MetroBaseball.png";

function createData(category, name, points, dateCompleted) {
  return { category, name, points, dateCompleted };
}
export function MyAccount({
  user,
  ethAddress,
  membershipId,
  customUsername,
  toggleDrawer,
  login,
  count,
  nftCount,
}) {
  const { width } = useViewport();
  const [pointHistoryLoaded, setPointHistoryLoaded] = useState(false);
  const [pointHistory, setPointHistory] = useState();
  const [points, setPoint] = useState();
  const [murals, setMural] = useState();
  const [modal, setModal] = useState(false);
  const reload = () => {
    login();
  };
  const redeemNFT = async () => {
    try {
      const result = await Moralis.Cloud.run("redeemNFT");
      console.log(result);
      getUserData();
    } catch (err) {
      console.log(err);
    }
  };

  const history = useNavigate();
  const getUserData = async () => {
    setPointHistoryLoaded(true);
    try {
      const params = {
        memberId: user.id,
      };
      const result = await Moralis.Cloud.run("getPointHistory", params);
      console.log(result);
      var rows = result?.map((achievement) =>
        createData(
          achievement.category,
          achievement.name,
          achievement.points,
          achievement.dateCompleted
        )
      );
      setPointHistory(rows);
    } catch (err) {
      console.log(err);
    }
    try {
      const params = {
        memberId: user.id,
      };
      const userStats = await Moralis.Cloud.run("getUserStats", params);
      console.log(userStats);
      setPoint(userStats);
    } catch (err) {
      console.log(err);
    }
    try {
      const params = {
        memberId: user.id,
      };
      const muralStats = await Moralis.Cloud.run("getMuralStats", params);
      const mStats = JSON.parse(JSON.stringify(muralStats));
      const prettifyMStats = {};
      for (var i = 0; i < mStats.length; i++) {
        prettifyMStats[mStats[i].muralId] = true;
      }
      setMural(prettifyMStats);
    } catch (err) {
      console.log(err);
    }
  };

  if (!pointHistoryLoaded) getUserData();
  console.log(user);
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      justifyItems="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        height: "100%",
        paddingTop: "10pt",
        paddingBottom: "40pt",
        width: "100%",
      }}
    >
      <Grid item width="100vw" style={{ maxWidth: "800pt" }}>
        <Grid container direction="row" justifyItems="flex-start">
          <Grid item style={{ margin: "10pt" }}>
            <Badge
              badgeContent={
                count + (!isNaN(parseInt(nftCount)) ? parseInt(nftCount) : 0)
              }
              variant="standard"
              color="success"
            >
              <IconButton onClick={toggleDrawer(true)}>
                <Menu style={{ color: "white" }} />
              </IconButton>
            </Badge>
          </Grid>
          <Grid item style={{ margin: "auto" }}>
            <a href={"/"}>
              <img
                src={MetroBaseball}
                style={{ maxWidth: "100%", maxHeight: "40pt" }}
              />
            </a>
          </Grid>
          <Grid item style={{ margin: "10pt" }}>
            <IconButton>
              <Menu style={{ color: "transparent" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          style={{
            marginTop: "10pt",
            fontFamily: "Road_Rage",
            fontSize: "2em",
            marginBottom: "10pt",
            color: "#eb5454",
          }}
        >
          The Journey
        </Typography>
        <FAQModal modal={modal} handleClose={() => setModal(false)} />
        <img
          src={metro}
          width="100%"
          style={{
            maxWidth: "400pt",
            maxHeight: "150pt",
            objectFit: "cover",
            borderRadius: "10pt",
            boxShadow: "0pt 0pt 3pt #eb5454",
            marginBottom: "10pt",
          }}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              style={{
                color: "rbga(243, 243, 243, 0.97)",
                fontSize: "large",
                fontFamily: "Roboto, sans-serif",
                fontWeight: "normal",
                padding: "10pt",
                maxWidth: "300pt",
              }}
            >
              Redeem Reward Points To Unlock Your NFT.{" "}
              <IconButton
                onClick={() => setModal(true)}
                style={{ margin: 0, padding: 0 }}
              >
                <Info style={{ color: "grey" }} />
              </IconButton>
              <br />
              <br />
              Earn This Digital Asset To Recieve Exclusive AirDrop Prizes,
              Artwork, Benefits And So Much More
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          style={{ margin: "auto", marginBottom: "10pt", marginTop: "10pt" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
            style={{ maxWidth: "300pt", margin: "auto", minWidth: "250pt" }}
          >
            <Grid item>
              <Box
                border={1}
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Points"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Pangolin, cursive",
                    }}
                  >
                    {points?.points}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box
                border={1}
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Mural"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Pangolin, cursive",
                    }}
                  >
                    {(points?.murals ? points.murals : "0") + "/12"}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <img
        src={MyCanvas}
        style={{
          width: width < 600 ? "100%" : "",
          padding: "10pt",
          maxHeight: "90vh",
        }}
      /> */}
      {points?.requestNFT ? (
        <Grid item>
          <img
            src={fullrend}
            width="100%"
            style={{
              maxWidth: "300pt",
              padding: "5pt",
              boxShadow: "0px 0px 25px white",
              borderRadius: "10pt",
            }}
          ></img>
        </Grid>
      ) : (
        <Grid item>
          <MainBody
            redemption={true}
            selectedUser={points}
            muralStats={murals}
            reload={reload}
          />
        </Grid>
      )}
      {points?.requestNFT ? (
        <div
          style={{
            width: "100%",
            maxWidth: "400pt",
            background: "transparent",
            border: "none",
          }}
        >
          <div
            className="ticket"
            style={{
              overflow: "clip",
              marginLeft: "25pt",
              marginRight: "25pt",
              marginTop: "10pt",
              marginBottom: "10pt",
            }}
          >
            <div className="ticket-content-wrapper">
              <Typography style={{ fontWeight: "bold" }}>
                NFT Redeemed!
              </Typography>
              <Typography style={{ fontSize: "small", padding: "10pt" }}>
                Please allow up to 7 days for Metroflex to review your
                redemption.
              </Typography>
              <Typography style={{ fontSize: "small", padding: "10pt" }}>
                When approved, you can view your NFT in your crypto wallet.
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        murals &&
        murals[0] === true && (
          <button
            onClick={() => {
              redeemNFT();
            }}
            style={{
              width: "100%",
              maxWidth: "400pt",
              background: "transparent",
              border: "none",
            }}
          >
            <div
              className="ticket"
              style={{
                overflow: "clip",
                marginLeft: "25pt",
                marginRight: "25pt",
                marginTop: "10pt",
                marginBottom: "10pt",
              }}
            >
              <div className="ticket-content-wrapper">
                <Typography
                  style={{
                    color: "white",
                    fontSize: "large",
                    fontWeight: "bold",
                  }}
                >
                  Get NFT
                </Typography>
              </div>
            </div>
          </button>
        )
      )}
    </Grid>
  );
}
