import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Container,
  TableRow,
} from "@mui/material";
import METROFLEX from "../assets/NFT_Promp1.png";
import MyCanvas from "../assets/MyCanvas.gif";
import DefaultAvatar from "../assets/defaultAvatar.png";
import DefaultAvatarF from "../assets/defaultAvatarF.png";
import { SectionHeader } from "./SectionHeader";
import { useViewport } from "../viewport";
import {
  Cancel,
  CancelOutlined,
  Check,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Menu,
} from "@mui/icons-material";
import { useState } from "react";
import Moralis from "moralis";
import { MainBody } from "./MainBody";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import logo from "../assets/logo.png";
import { useFilePicker } from "use-file-picker";
import MetroBaseball from "../assets/MetroBaseball.png";
import { TopNavBar } from "./TopNavBar";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: 400,
  bgcolor: "black",
  p: 2,
  px: 4,
  pb: 3,
  color: "white",
};

function createData(category, name, points, dateCompleted) {
  return { category, name, points, dateCompleted };
}
export function MyProfile({
  user,
  ethAddress,
  membershipId,
  customUsername,
  toggleDrawer,
  login,
}) {
  const { width } = useViewport();
  const [pointHistoryLoaded, setPointHistoryLoaded] = useState(false);
  const [pointHistory, setPointHistory] = useState();
  const [points, setPoint] = useState();
  const [murals, setMural] = useState();
  const [welcome, setWelcome] = useState(true);
  const [PRBonus, setPRBonus] = useState(true);
  const [PicBonus, setPicBonus] = useState(true);
  const [BioBonus, setBioBonus] = useState(true);
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [benchPR, setBenchPR] = useState("");
  const [squatPR, setSquatPR] = useState("");
  const [clean_jerkPR, setClean_JerkPR] = useState("");
  const [snatchPR, setSnatchPR] = useState("");
  const [deadliftPR, setDeadliftPR] = useState("");
  const [mileMinPR, setMileMinPR] = useState("");
  const [mileSecPR, setMileSecPR] = useState("");
  const [image, setImage] = useState();
  const [customBio, setCustomBio] = useState("");
  const [newQuote, setNewQuote] = useState(false);
  const [newQuoteText, setNewQuoteText] = useState("");
  const [modal, showModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => showModal(true);
  const handleClose = () => showModal(false);
  const rando = Math.random(2);

  const [openFileSelector, { filesContent, errors, loading2, clear }] =
    useFilePicker({
      readAs: "DataURL",
      accept: "image/*",
      multiple: false,
      maxFileSize: 50,
    });

  const uploadImage = async () => {
    const moralisFile = new Moralis.File(
      "upload",
      // + "ProfileImage:" + filesContent[0].name,
      { uri: filesContent[0].content }
    );
    await moralisFile.save().then(
      async function (value) {
        console.log(value);

        const currentUser = await Moralis.User.currentAsync();
        currentUser.set("image", value._url);
        await currentUser.save();
        if (!PicBonus) {
          const params = {
            username: customUsername ? customUsername : user.get("username"),
          };
          const run = await Moralis.Cloud.run("PicBonus", params);
          console.log(run);
        }
        getUserData();
        clear();
      },
      function (error) {
        console.log(error);
      }
    );
  };

  const updatePRs = async () => {
    const currentUser = await Moralis.User.currentAsync();
    currentUser.set("benchPR", parseInt(benchPR));
    currentUser.set("squatPR", parseInt(squatPR));
    currentUser.set("deadliftPR", parseInt(deadliftPR));
    currentUser.set("snatchPR", parseInt(snatchPR));
    currentUser.set("clean_jerkPR", parseInt(clean_jerkPR));
    currentUser.set(
      "milePR",
      parseInt(parseInt(mileMinPR) * 60 + parseInt(mileSecPR))
    );
    await currentUser.save();
    if (!PRBonus) {
      const params = {
        username: customUsername ? customUsername : user.get("username"),
      };
      const run = await Moralis.Cloud.run("PRBonus", params);
      console.log(run);
    }
    getUserData();
    setEditing(false);
    handleOpen();
  };

  const getUserData = async () => {
    setPointHistoryLoaded(true);
    try {
      const params = {
        memberId: user.id,
      };
      const result = await Moralis.Cloud.run("getPointHistory", params);
      console.log(result);
      var rows = result?.map((achievement) =>
        createData(
          achievement.category,
          achievement.name,
          achievement.points,
          achievement.dateCompleted
        )
      );
      setPointHistory(rows);
    } catch (err) {
      console.log(err);
    }
    try {
      const params = {
        memberId: user.id,
      };
      const userStats = await Moralis.Cloud.run("getUserStats", params);
      console.log(userStats);
      setImage(userStats.image);
      setPoint(userStats);
      setBenchPR(userStats.benchPR);
      setSquatPR(userStats.squatPR);
      setDeadliftPR(userStats.deadliftPR);
      setSnatchPR(userStats.snatchPR);
      setClean_JerkPR(userStats.clean_jerkPR);
      setCustomBio(userStats.bio);
      setMileMinPR(Math.floor(userStats.milePR / 60).toFixed(0));
      setMileSecPR(
        userStats.milePR -
          parseInt(Math.floor(userStats.milePR / 60).toFixed(0)) * 60
      );
      setWelcome(
        userStats.welcomeAcknowledged ? userStats.welcomeAcknowledged : false
      );
      setPRBonus(userStats.PRBonus ? userStats.PRBonus : false);
      setPicBonus(userStats.PicBonus ? userStats.PicBonus : false);
      setBioBonus(userStats.BioBonus ? userStats.BioBonus : false);
    } catch (err) {
      console.log(err);
    }
    try {
      const params = {
        memberId: user.id,
      };
      const muralStats = await Moralis.Cloud.run("getMuralStats", params);
      const mStats = JSON.parse(JSON.stringify(muralStats));
      const prettifyMStats = {};
      for (var i = 0; i < mStats.length; i++) {
        prettifyMStats[mStats[i].muralId] = true;
      }
      setMural(prettifyMStats);
    } catch (err) {
      console.log(err);
    }
  };

  const submitNewQuote = async () => {
    const currentUser = await Moralis.User.currentAsync();
    currentUser.set("bio", newQuoteText);
    await currentUser.save();
    if (!BioBonus) {
      const params = {
        username: customUsername ? customUsername : user.get("username"),
      };
      const run = await Moralis.Cloud.run("BioBonus", params);
      console.log(run);
    }
    getUserData();
    setNewQuote(false);
    setNewQuoteText("");
  };

  const submitWelcomeBonus = async () => {
    const params = {
      username: customUsername ? customUsername : user.username,
    };
    await Moralis.Cloud.run("welcomeBonus", params);
    getUserData();
    setNewQuote(false);
    setNewQuoteText("");
    login();
  };

  if (!pointHistoryLoaded) getUserData();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      justifyItems="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        height: "100%",
        paddingTop: "10pt",
        paddingBottom: "40pt",
        width: "100%",
      }}
    >
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modal}
        onClose={handleClose}
        onBackdropClick={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Grid container direction="column" alignItems="center">
            <Check />
            <Typography style={{ fontSize: "large" }}>
              Your PRs have been updated!
            </Typography>
            <img src={logo} width="200pt"></img>
            <Typography
              style={{
                fontSize: "x-large",
                color: "#eb5454",
                fontFamily: "Requiem, sans-serif",
              }}
            >
              {rando === 1 ? "Keep up the hard work" : "You're killing it"}
            </Typography>
          </Grid>
        </Box>
      </StyledModal>
      <StyledModal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={loading}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Grid container direction="column" alignItems="center">
            <Typography
              style={{
                fontFamily: "Requiem, sans-serif",
              }}
            >
              Uploading User Picture
            </Typography>
            <CircularProgress style={{ color: "#eb5454" }} />
          </Grid>
        </Box>
      </StyledModal>
      <TopNavBar toggleDrawer={toggleDrawer} />
      {welcome === false && (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent={"space-between"}
          alignItems="center"
          style={{
            background: "#eb5454",
            borderRadius: "10pt",
            paddingLeft: "10pt",
            paddingRight: "10pt",
          }}
        >
          <Grid container direction="column">
            <Typography align="left">Claim your Welcome BONUS!</Typography>
            <Typography align="left" fontWeight={"bold"}>
              +100 points
            </Typography>
          </Grid>
          <Button
            onClick={() => submitWelcomeBonus()}
            variant="outlined"
            style={{
              color: "white",
              borderColor: "white",
              borderRadius: "10pt",
            }}
          >
            CLAIM
          </Button>
        </Grid>
      )}
      <Typography
        style={{
          marginTop: "10pt",
          fontFamily: "Road_Rage",
          fontSize: "2em",
          marginBottom: "10pt",
          color: "#eb5454",
        }}
      >
        {"My Profile"}
      </Typography>
      {newQuote ? (
        <Grid
          container
          direction="column"
          alignItems={"center"}
          style={{
            maxWidth: "300pt",
            color: "white",
          }}
        >
          <Grid item>
            <Grid container direction="row">
              <TextField
                value={newQuoteText}
                placeholder={
                  customBio !== ""
                    ? customBio
                    : "You are not special fuck you. - Bentley"
                }
                variant="outlined"
                sx={{
                  focus: {
                    outlineColor: "white",
                  },
                  input: {
                    color: "white",
                    padding: "2pt",
                    fontFamily: "Requiem, sans-serif",
                    fontSize: "large",
                    textAlign: "center",
                  },
                  "& .MuiInputBase-root": {
                    color: "white",
                    padding: "2pt",
                    fontFamily: "Requiem, sans-serif",
                    fontSize: "large",
                    textAlign: "center",
                    outlineColor: "white",
                  },
                }}
                style={{ color: "white" }}
                multiline
                rows={2}
                autoComplete="off"
                onChange={(newVal) => {
                  if (newVal.target.value.length <= 64)
                    setNewQuoteText(newVal.target.value);
                }}
              />

              <Badge
                badgeContent={BioBonus ? null : "25pt"}
                color="success"
                style={{ color: "white" }}
              >
                <Button
                  style={{
                    backgroundColor: "#eb5454",
                    color: "white",
                    maxHeight: "30pt",
                    textTransform: "none",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    submitNewQuote();
                  }}
                >
                  <Check />
                </Button>
              </Badge>
            </Grid>
          </Grid>
          <Typography
            style={{
              fontSize: "x-small",
              color:
                newQuoteText.length <= 55
                  ? "white"
                  : newQuoteText.length < 64
                  ? "yellow"
                  : "red",
            }}
            align="right"
          >
            {newQuoteText.length}/64
          </Typography>
          <Button
            style={{
              backgroundColor: "grey",
              color: "white",
              textTransform: "none",
            }}
            onClick={(event) => {
              event.preventDefault();
              setNewQuote(false);
              setNewQuoteText("");
            }}
          >
            Cancel
          </Button>
        </Grid>
      ) : customBio !== "" ? (
        <Typography
          style={{
            fontFamily: "Requiem, sans-serif",
            fontSize: "large",
          }}
        >
          {customBio}{" "}
          <IconButton
            onClick={(event) => {
              setNewQuote(true);
            }}
          >
            <Edit style={{ color: "white" }} />
          </IconButton>
        </Typography>
      ) : (
        <Typography
          style={{
            fontFamily: "Requiem, sans-serif",
            fontSize: "large",
          }}
        >
          You are not special
          <br /> fuck you.
          <br />- Bentley
          <IconButton
            onClick={(event) => {
              setNewQuote(true);
            }}
          >
            <Badge
              badgeContent={BioBonus ? null : "25pt"}
              color="success"
              style={{ color: "white" }}
            >
              <Edit style={{ color: "white" }} />
            </Badge>
          </IconButton>
        </Typography>
      )}
      <Grid item>
        <Grid item style={{ margin: "auto" }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            alignContent="flex-end"
            justifyContent="center"
            wrap="nowrap"
            style={{
              width: "90vw",
              maxWidth: "400pt",
              margin: "auto",
              marginTop: "20pt",
            }}
          >
            <Grid item>
              <Grid container direction="column">
                <img
                  src={
                    filesContent[0] !== undefined
                      ? filesContent[0]?.content
                      : image !== null && image !== "default"
                      ? image
                      : DefaultAvatar
                  }
                  style={{
                    width: width < 600 ? "80pt" : "90pt",
                    height: width < 600 ? "80pt" : "90pt",
                    borderRadius: "50%",
                    padding:
                      filesContent[0] !== undefined
                        ? "5pt"
                        : image !== null && image !== "default"
                        ? "5pt"
                        : "",
                    objectFit: "cover",
                  }}
                />
                {filesContent[0] !== undefined ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{ width: "100%" }}
                  >
                    <Button
                      onClick={() => clear()}
                      style={{
                        fontSize: "small",
                        background: "grey",
                        borderRadius: "5pt",
                        overflow: "clip",
                        width: "45%",
                        padding: 0,
                        color: "white",
                      }}
                    >
                      Cancel
                    </Button>

                    <Badge
                      badgeContent={PicBonus ? null : "25pt"}
                      color="success"
                      style={{ color: "white" }}
                    >
                      <Button
                        onClick={async () => {
                          setLoading(true);
                          await uploadImage();
                          setLoading(false);
                        }}
                        style={{
                          fontSize: "small",
                          background: "green",
                          borderRadius: "5pt",
                          overflow: "clip",
                          width: "45%",
                          padding: 0,
                          color: "white",
                        }}
                      >
                        Save
                      </Button>
                    </Badge>
                  </Grid>
                ) : (
                  <Badge
                    badgeContent={PicBonus ? null : "50pt"}
                    color="success"
                    style={{ color: "white" }}
                  >
                    <Button
                      onClick={() => openFileSelector()}
                      style={{
                        fontSize: "small",
                        background: "grey",
                        borderRadius: "5pt",
                        overflow: "clip",
                        textTransform: "none",
                        padding: 0,
                        color: "white",
                        width: "100%",
                      }}
                    >
                      Change
                    </Button>
                  </Badge>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="flex-start">
                <Typography
                  style={{
                    fontSize: "xx-large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  {customUsername}
                </Typography>
                <Typography
                  style={{
                    fontSize: "large",
                    fontWeight: "bold",
                    fontFamily: "Pangolin, cursive",
                  }}
                >
                  {membershipId}
                </Typography>
                <Typography style={{ fontSize: "small" }}>
                  {ethAddress && width < 600
                    ? ethAddress.slice(0, 18) + "..."
                    : ethAddress}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Typography
            style={{
              marginTop: "20pt",
              marginBottom: "10pt",
              fontSize: "x-large",
              color: "#eb5454",
              fontFamily: "Requiem, sans-serif",
            }}
            align="left"
          >
            My Stats
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
            style={{ maxWidth: "300pt", margin: "auto" }}
          >
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Points"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Pangolin, cursive",
                    }}
                  >
                    {points?.points}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Mural"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Pangolin, cursive",
                    }}
                  >
                    {(points?.murals ? points.murals : "0") + "/12"}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Tickets"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Pangolin, cursive",
                    }}
                  >
                    {points?.tickets ? points.tickets : "0"}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Typography
            style={{
              marginTop: "30pt",
              marginBottom: "10pt",
              fontSize: "x-large",
              color: "#eb5454",
              fontFamily: "Requiem, sans-serif",
            }}
            align="left"
          >
            Personal Records{" "}
            {editing === true ? (
              <IconButton
                onClick={() => {
                  setBenchPR(points?.benchPR);
                  setSquatPR(points?.squatPR);
                  setDeadliftPR(points?.deadliftPR);
                  setMileMinPR(Math.floor(points?.milePR / 60).toFixed(0));
                  setMileSecPR(
                    points?.milePR -
                      parseInt(Math.floor(points?.milePR / 60).toFixed(0)) * 60
                  );
                  setEditing(false);
                }}
              >
                <CancelOutlined style={{ color: "white" }} />
              </IconButton>
            ) : (
              <Badge
                badgeContent={points?.PRBonus === true ? null : "25pt"}
                color="success"
                style={{ color: "white" }}
              >
                <IconButton onClick={() => setEditing(true)}>
                  <Edit style={{ color: "white" }} />
                </IconButton>
              </Badge>
            )}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
            style={{ maxWidth: "300pt", margin: "auto" }}
          >
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column" justifyContent="center">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Bench PR"}
                  </Typography>
                  {editing === true ? (
                    <Grid container direction="row" alignItems="center">
                      <TextField
                        id="benchPRTextField"
                        variant="outlined"
                        margin="dense"
                        value={benchPR}
                        autoComplete="off"
                        onChange={(event) => {
                          if (event.target.value === "") {
                            setBenchPR("");
                          }
                          if (parseInt(event.target.value) < 1000)
                            setBenchPR(parseInt(event.target.value).toString());
                        }}
                        sx={{
                          focus: {
                            outlineColor: "white",
                          },
                          input: {
                            color: "white",
                            padding: "2pt",
                            fontFamily: "Pangolin, cursive",
                            textAlign: "center",
                          },
                        }}
                        style={{
                          maxWidth: "40pt",
                          color: "white",
                        }}
                      ></TextField>
                      <Typography
                        style={{
                          fontFamily: "Pangolin, cursive",
                        }}
                      >
                        lbs.
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "large",
                        fontFamily: "Pangolin, cursive",
                      }}
                    >
                      {(points?.benchPR ? points.benchPR : "0") + " lbs."}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Squat PR"}
                  </Typography>
                  {editing === true ? (
                    <Grid container direction="row" alignItems="center">
                      <TextField
                        id="benchPRTextField"
                        variant="outlined"
                        margin="dense"
                        value={squatPR}
                        autoComplete="off"
                        onChange={(event) => {
                          if (event.target.value === "") {
                            setSquatPR("");
                          }
                          if (parseInt(event.target.value) < 1000)
                            setSquatPR(parseInt(event.target.value).toString());
                        }}
                        sx={{
                          focus: {
                            outlineColor: "white",
                          },
                          input: {
                            color: "white",
                            padding: "2pt",
                            fontFamily: "Pangolin, cursive",
                            textAlign: "center",
                          },
                        }}
                        style={{ maxWidth: "40pt", color: "white" }}
                      ></TextField>
                      <Typography
                        style={{
                          fontFamily: "Pangolin, cursive",
                        }}
                      >
                        lbs.
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "large",
                        fontFamily: "Pangolin, cursive",
                      }}
                    >
                      {(points?.squatPR ? points.squatPR : "0") + " lbs."}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Mile PR"}
                  </Typography>
                  {editing === true ? (
                    <Grid container direction="row" alignItems="center">
                      <TextField
                        id="benchPRTextField"
                        variant="outlined"
                        margin="dense"
                        value={mileMinPR}
                        autoComplete="off"
                        onChange={(event) => {
                          if (event.target.value === "") {
                            setMileMinPR("");
                          }
                          if (parseInt(event.target.value) < 100)
                            setMileMinPR(
                              parseInt(event.target.value).toString()
                            );
                        }}
                        sx={{
                          focus: {
                            outlineColor: "white",
                          },

                          input: {
                            color: "white",
                            padding: "2pt",
                            fontFamily: "Pangolin, cursive",
                            textAlign: "center",
                          },
                        }}
                        style={{
                          maxWidth: "30pt",
                          color: "white",
                        }}
                      ></TextField>
                      :
                      <TextField
                        id="benchPRTextField"
                        variant="outlined"
                        margin="dense"
                        type="number"
                        autoComplete="off"
                        value={mileSecPR}
                        onChange={(event) => {
                          if (event.target.value === "") {
                            setMileSecPR("");
                          }
                          if (parseInt(event.target.value) < 60)
                            setMileSecPR(
                              parseInt(event.target.value).toString()
                            );
                        }}
                        sx={{
                          focus: {
                            outlineColor: "white",
                          },
                          input: {
                            color: "white",
                            padding: "2pt",
                            fontFamily: "Pangolin, cursive",
                            textAlign: "center",
                          },
                        }}
                        style={{
                          maxWidth: "30pt",
                          color: "white",
                        }}
                      ></TextField>
                    </Grid>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "large",
                        fontFamily: "Pangolin, cursive",
                      }}
                    >
                      {points?.milePR
                        ? parseInt(
                            Math.floor(points.milePR / 60).toFixed(0)
                          ).toString() +
                          ":" +
                          (parseInt(
                            points.milePR -
                              parseInt(
                                Math.floor(points.milePR / 60).toFixed(0)
                              ) *
                                60
                          ) < 10
                            ? "0"
                            : "") +
                          parseInt(
                            points.milePR -
                              parseInt(
                                Math.floor(points.milePR / 60).toFixed(0)
                              ) *
                                60
                          ).toString()
                        : "0:00"}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="flex-end"
            wrap="nowrap"
            style={{ maxWidth: "300pt", margin: "auto" }}
          >
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column" justifyContent="center">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Deadlift PR"}
                  </Typography>
                  {editing === true ? (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        id="deadliftPRTextField"
                        variant="outlined"
                        margin="dense"
                        value={deadliftPR}
                        autoComplete="off"
                        onChange={(event) => {
                          if (event.target.value === "") {
                            setDeadliftPR("");
                          }
                          if (parseInt(event.target.value) < 1500)
                            setDeadliftPR(
                              parseInt(event.target.value).toString()
                            );
                        }}
                        sx={{
                          focus: {
                            outlineColor: "white",
                          },
                          input: {
                            color: "white",
                            padding: "2pt",
                            fontFamily: "Pangolin, cursive",
                            textAlign: "center",
                          },
                        }}
                        style={{
                          maxWidth: "40pt",
                          color: "white",
                        }}
                      ></TextField>
                      <Typography
                        style={{
                          fontFamily: "Pangolin, cursive",
                        }}
                      >
                        lbs.
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "large",
                        fontFamily: "Pangolin, cursive",
                      }}
                    >
                      {(points?.deadliftPR ? points.deadliftPR : "0") + " lbs."}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>{" "}
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column" justifyContent="center">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Clean/Jerk PR"}
                  </Typography>
                  {editing === true ? (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        id="Clean/JerkPRTextField"
                        variant="outlined"
                        margin="dense"
                        value={clean_jerkPR}
                        autoComplete="off"
                        onChange={(event) => {
                          if (event.target.value === "") {
                            setClean_JerkPR("");
                          }
                          if (parseInt(event.target.value) < 1500)
                            setClean_JerkPR(
                              parseInt(event.target.value).toString()
                            );
                        }}
                        sx={{
                          focus: {
                            outlineColor: "white",
                          },
                          input: {
                            color: "white",
                            padding: "2pt",
                            fontFamily: "Pangolin, cursive",
                            textAlign: "center",
                          },
                        }}
                        style={{
                          maxWidth: "40pt",
                          color: "white",
                        }}
                      ></TextField>
                      <Typography
                        style={{
                          fontFamily: "Pangolin, cursive",
                        }}
                      >
                        lbs.
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "large",
                        fontFamily: "Pangolin, cursive",
                      }}
                    >
                      {(points?.clean_jerkPR ? points.clean_jerkPR : "0") +
                        " lbs."}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>{" "}
            <Grid item>
              <Box
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  borderColor: "#eb5454",
                  margin: "auto",
                }}
              >
                <Grid container direction="column" justifyContent="center">
                  <Typography
                    style={{
                      fontSize: "large",
                      fontFamily: "Requiem, sans-serif",
                    }}
                  >
                    {"Snatch PR"}
                  </Typography>
                  {editing === true ? (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        id="SnatchPRTextField"
                        variant="outlined"
                        margin="dense"
                        value={snatchPR}
                        autoComplete="off"
                        onChange={(event) => {
                          if (event.target.value === "") {
                            setSnatchPR("");
                          }
                          if (parseInt(event.target.value) < 1500)
                            setSnatchPR(
                              parseInt(event.target.value).toString()
                            );
                        }}
                        sx={{
                          focus: {
                            outlineColor: "white",
                          },
                          input: {
                            color: "white",
                            padding: "2pt",
                            fontFamily: "Pangolin, cursive",
                            textAlign: "center",
                          },
                        }}
                        style={{
                          maxWidth: "40pt",
                          color: "white",
                        }}
                      ></TextField>
                      <Typography
                        style={{
                          fontFamily: "Pangolin, cursive",
                        }}
                      >
                        lbs.
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "large",
                        fontFamily: "Pangolin, cursive",
                      }}
                    >
                      {(points?.snatchPR ? points.snatchPR : "0") + " lbs."}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {editing === true && (
            <Badge
              badgeContent={points?.PRBonus ? null : "25pt"}
              color="success"
              style={{ color: "white" }}
            >
              <Button
                variant="contained"
                onClick={(event) => {
                  event.preventDefault();
                  updatePRs();
                }}
                style={{
                  marginBottom: "20pt",
                  background: "#eb5454",
                  textTransform: "none",
                }}
              >
                Update PRs
              </Button>
            </Badge>
          )}
        </Grid>
      </Grid>
      {/* <img
      src={MyCanvas}
      style={{
        width: width < 600 ? "100%" : "",
        padding: "10pt",
        maxHeight: "90vh",
      }}
    /> */}
      <Grid item>
        <Box
          border={1}
          style={{
            padding: "10pt",
            height: "100%",
            width: "90vw",
            maxWidth: "700pt",
            borderRadius: "10pt",
            fontFamily: "Pangolin, cursive",
            margin: "10pt",
          }}
        >
          <TableContainer sx={{ maxHeight: "100%" }}>
            <Table size="small" sx={{ width: "100%", maxWidth: "600pt" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#eb5454",
                      fontFamily: "Requiem, sans-serif",
                      fontSize: width > 700 ? "1.1em" : "1.5em",
                    }}
                    colSpan={3}
                  >
                    My History
                    {open === true ? (
                      <IconButton onClick={() => setOpen(false)}>
                        <KeyboardArrowUp style={{ color: "white" }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => setOpen(true)}>
                        <KeyboardArrowDown style={{ color: "white" }} />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
                {open === true && (
                  <TableRow>
                    <TableCell
                      style={{
                        color: "white",
                        fontFamily: "Requiem, sans-serif",
                        fontSize: "x-large",
                      }}
                    >
                      name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontFamily: "Requiem, sans-serif",
                        fontSize: "x-large",
                      }}
                    >
                      points
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontFamily: "Requiem, sans-serif",
                        fontSize: "x-large",
                      }}
                    >
                      category
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontFamily: "Requiem, sans-serif",
                        fontSize: "x-large",
                      }}
                    >
                      date
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {open === true &&
                  pointHistory?.map((row, i) => (
                    <TableRow
                      key={row.name + i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: "white",
                          fontFamily: "Requiem, sans-serif",
                          fontSize: "large",
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "white",
                          fontFamily: "Requiem, sans-serif",
                          fontSize: "large",
                        }}
                      >
                        {row.points}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: "white",
                          fontFamily: "Requiem, sans-serif",
                          fontSize: "large",
                        }}
                      >
                        {row.category}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "white",
                          fontFamily: "Requiem, sans-serif",
                          fontSize: "large",
                        }}
                      >
                        {new Date(row.dateCompleted).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}
