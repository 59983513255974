import {
  Add,
  Check,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  PendingActions,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Moralis from "moralis";
import { useState } from "react";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
const formatter = buildFormatter(englishStrings);
export function MyHitListModal({
  pending,
  myHitList,
  myPoints,
  setCategories,
  setLoading,
  user,
  setPoints,
  showCongrats,
  setMyHitList,
  getAchievements,
}) {
  const [showPending, setShowPending] = useState(false);
  const [showList, setShowList] = useState(false);
  const [init, setInit] = useState(false);
  const [pendingPoints, setPendingPoints] = useState(10);
  const memberAddAchievement = async () => {
    setCategories([]);
    setLoading(true);
    const params = {
      achievements: myHitList,
      memberId: JSON.parse(JSON.stringify(user)).objectId,
      username: JSON.parse(JSON.stringify(user)).customUsername
        ? JSON.parse(JSON.stringify(user)).customUsername
        : JSON.parse(JSON.stringify(user)).username,
    };
    const cal = await Moralis.Cloud.run("addAchievementsMember", params);
    console.log(cal);
    setPoints(0);
    setMyHitList([]);
    setLoading(false);
    showCongrats(true);
    getAchievements();
  };

  if (!init && pending.length > 0) {
    console.log(pending);
    setInit(true);
    setPendingPoints(0);
    for (var i = 0; pending.length < i; i++) {
      setPendingPoints(pendingPoints + pending[i].points);
    }
  }

  return (
    <Container
      style={{
        padding: "10pt",
        width: "100%",
        maxWidth: "800pt",
        position: "fixed",
        bottom: 0,
        background: "rgba(20,20,20,1)",
        borderTopLeftRadius: "5pt",
        borderTopRightRadius: "5pt",
        boxShadow: "0pt 0pt 3pt #eb5454",
        paddingTop: "10pt",
      }}
    >
      {myHitList.length === 0 ? (
        showPending === true ? (
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent={"space-between"}
            alignItems="flex-start"
            zIndex={1}
          >
            <KeyboardDoubleArrowDown style={{ color: "rgba(20,20,20,1)" }} />
            <Container
              style={{
                borderRadius: "5pt",
                padding: "10pt",
                width: "75%",
                maxWidth: "200pt",
              }}
            >
              <Grid item style={{ width: "100%" }}>
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  Pending approvals
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  style={{ marginTop: "10pt", marginBottom: "10pt" }}
                >
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Typography
                        style={{
                          color: "#eb5454",

                          fontFamily: "Requiem, sans-serif",
                        }}
                      >
                        Hits
                      </Typography>
                      <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                        {pending.length}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Typography
                        style={{
                          color: "#eb5454",

                          fontFamily: "Requiem, sans-serif",
                        }}
                      >
                        Points
                      </Typography>
                      <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                        {pendingPoints}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {pending.map((achievement) => {
                return (
                  <Grid
                    container
                    key={achievement.id}
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-around"
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{ fontWeight: "bold", marginRight: "3pt" }}
                        >
                          {achievement.name}
                        </Typography>
                        <Typography style={{ color: "green" }}>
                          {"+" + achievement.points}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography style={{ fontSize: "small" }} noWrap>
                      <TimeAgo
                        date={new Date(achievement.dateCompleted)}
                        formatter={formatter}
                      />
                    </Typography>
                  </Grid>
                );
              })}
            </Container>
            <IconButton
              onClick={() => {
                setShowPending(false);
              }}
            >
              <KeyboardDoubleArrowDown style={{ color: "white" }} />
            </IconButton>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent={"space-between"}
            zIndex={1}
          >
            <Typography>Cross off achievements to begin.</Typography>
            <Grid item>
              <Badge badgeContent={pending.length} style={{ color: "white" }}>
                <IconButton
                  onClick={() => {
                    setShowPending(!showPending);
                  }}
                  disabled={pending.length === 0}
                >
                  <PendingActions
                    style={{
                      color: pending.length > 0 ? "white" : "rgba(20,20,20,1)",
                    }}
                  />
                </IconButton>
              </Badge>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent={"space-between"}
          alignItems="flex-start"
          zIndex={1}
        >
          <Badge badgeContent={pending.length} style={{ color: "white" }}>
            <IconButton
              onClick={() => {
                setShowPending(!showPending);
              }}
              disabled={pending.length === 0}
            >
              <PendingActions
                style={{
                  color: pending.length > 0 ? "white" : "rgba(20,20,20,1)",
                }}
              />
            </IconButton>
          </Badge>
          {showPending === true && pending.length > 0 ? (
            <Container
              style={{
                borderRadius: "5pt",
                padding: "10pt",
                width: "75%",
                maxWidth: "200pt",
              }}
            >
              <Grid item style={{ width: "100%" }}>
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  Pending approvals
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  style={{ marginTop: "10pt", marginBottom: "10pt" }}
                >
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Typography
                        style={{
                          color: "#eb5454",

                          fontFamily: "Requiem, sans-serif",
                        }}
                      >
                        Hits
                      </Typography>
                      <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                        {pending.length}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Typography
                        style={{
                          color: "#eb5454",

                          fontFamily: "Requiem, sans-serif",
                        }}
                      >
                        Points
                      </Typography>
                      <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                        {pendingPoints}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {pending.map((achievement) => {
                return (
                  <Grid
                    container
                    key={achievement.id}
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-around"
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="flex-start"
                      >
                        <Typography
                          style={{ fontWeight: "bold", marginRight: "3pt" }}
                        >
                          {achievement.name}
                        </Typography>
                        <Typography style={{ color: "green" }}>
                          {"+" + achievement.points}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography style={{ fontSize: "small" }} noWrap>
                      <TimeAgo
                        date={new Date(achievement.dateCompleted)}
                        formatter={formatter}
                      />
                    </Typography>
                  </Grid>
                );
              })}
            </Container>
          ) : showList === false ? (
            <Grid item style={{ width: "100%" }}>
              <Typography
                style={{
                  fontSize: "large",
                  fontFamily: "Requiem, sans-serif",
                }}
              >
                Today
              </Typography>
              <Grid container direction="row" justifyContent="space-around">
                <Grid item>
                  <Grid container direction="column" justifyContent="center">
                    <Typography
                      style={{
                        color: "#eb5454",

                        fontFamily: "Requiem, sans-serif",
                      }}
                    >
                      Hits
                    </Typography>
                    <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                      {myHitList.length}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" justifyContent="center">
                    <Typography
                      style={{
                        color: "#eb5454",

                        fontFamily: "Requiem, sans-serif",
                      }}
                    >
                      Points
                    </Typography>
                    <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                      {myPoints}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Button
                variant="outlined"
                style={{
                  borderColor: "#eb5454",
                  color: "#eb5454",
                  marginTop: "5pt",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  memberAddAchievement();
                }}
                disabled={myHitList.length === 0}
              >
                Submit
              </Button>
            </Grid>
          ) : (
            <Grid item style={{ width: "100%" }}>
              <Typography
                style={{
                  fontSize: "large",
                  fontFamily: "Requiem, sans-serif",
                }}
              >
                Today
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                style={{ marginTop: "10pt", marginBottom: "10pt" }}
              >
                <Grid item>
                  <Grid container direction="column" justifyContent="center">
                    <Typography
                      style={{
                        color: "#eb5454",

                        fontFamily: "Requiem, sans-serif",
                      }}
                    >
                      Hits
                    </Typography>
                    <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                      {myHitList.length}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" justifyContent="center">
                    <Typography
                      style={{
                        color: "#eb5454",

                        fontFamily: "Requiem, sans-serif",
                      }}
                    >
                      Points
                    </Typography>
                    <Typography style={{ fontFamily: "Pangolin, cursive" }}>
                      {myPoints}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {myHitList.map((hit) => (
                <Grid
                  key={hit.objectId}
                  container
                  direction="row"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  {/* <Check /> */}
                  <Typography>{hit.name.toString()}</Typography>
                  <Typography style={{ color: "green", paddingLeft: "0.3em" }}>
                    {" +" + hit.points.toString()}
                  </Typography>
                </Grid>
              ))}
              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                style={{ marginTop: "20pt" }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderColor: "grey",
                    color: "grey",
                    marginTop: "5pt",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    setCategories([]);
                    setLoading(true);
                    setPoints(0);
                    setMyHitList([]);
                    setLoading(false);
                    getAchievements();
                  }}
                  disabled={myHitList.length === 0}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  style={{
                    background: "#eb5454",
                    color: "white",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    memberAddAchievement();
                  }}
                  disabled={myHitList.length === 0}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Grid container direction="row" wrap="nowrap">
                  <Typography
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "small",
                      width: "80%",
                    }}
                    align="left"
                  >
                    1) Cross off your hit list.
                    <br />
                    2) Click Submit when you are finished.
                  </Typography>
                  <Typography style={{ fontSize: "x-large" }}>☝️</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <IconButton onClick={() => setShowList(!showList)}>
              {showList === false ? (
                <KeyboardDoubleArrowUp
                  style={{ color: showPending ? "rgba(20,20,20,1)" : "white" }}
                />
              ) : (
                <KeyboardDoubleArrowDown
                  style={{ color: showPending ? "rgba(20,20,20,1)" : "white" }}
                />
              )}
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
