import { Cancel, Check, Edit, KeyboardArrowUp } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Moralis from "moralis";
import { useState } from "react";
import { useViewport } from "../viewport";

export function MyHitListCategory({
  category,
  user,
  getAchievements,
  opened,
  index,
  setOpen,
}) {
  const { width } = useViewport();
  const [clicked, setClicked] = useState(false);
  const [newCategory, setCategory] = useState(category);

  const updateCategoryName = async () => {
    const params = {
      newCategory: newCategory,
      oldCategory: category,
    };
    const update = await Moralis.Cloud.run("editAchievementCategory", params);
    console.log(update);
    setCategory("");
    getAchievements();
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            color: "#eb5454",
            fontFamily: "Requiem, sans-serif",
            fontSize: (width > 700 ? "x" : "") + "x-large",
          }}
          onClick={
            opened[index]
              ? () => {
                  const t = JSON.parse(JSON.stringify(opened));
                  t[index] = false;
                  setOpen(t);
                }
              : () => {}
          }
          colSpan={2}
        >
          {clicked ? (
            <Input
              style={{
                color: "white",
                fontFamily: "Requiem, sans-serif",
              }}
              multiline
              inputStyle={{ color: "red" }} // Add this to your code
              placeholder="New Category"
              value={newCategory}
              onChange={(newValue) => {
                newValue.preventDefault();
                setCategory(newValue.target.value);
              }}
              autoCapitalize="none"
              autoCorrect={false}
              //leftIcon={<Icon name="email" size={20} color="#B3C1B3" />}
            />
          ) : (
            category
          )}
          {(user?.id === "BcxIGp8FarTgazB7P4FvwEJ2" ||
            user?.id === "XhfOK2iA0coZGFlBwHRHGGAe") && (
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                clicked ? setClicked(false) : setClicked(true);
              }}
            >
              {clicked ? (
                <Grid container direction="row" justifyItems="flex-end">
                  <IconButton
                    onClick={(event) => {
                      event.preventDefault();
                      updateCategoryName();
                    }}
                  >
                    <Check style={{ color: "green" }} />
                  </IconButton>
                  <IconButton>
                    <Cancel style={{ color: "white" }} />
                  </IconButton>
                </Grid>
              ) : (
                <Edit style={{ color: "white" }} />
              )}
            </IconButton>
          )}
          {opened[index] && (
            <IconButton
              onClick={() => {
                const t = JSON.parse(JSON.stringify(opened));
                t[index] = false;
                setOpen(t);
              }}
            >
              <KeyboardArrowUp style={{ color: "white" }} />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
