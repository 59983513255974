import {
  AddCircleOutline,
  Cancel,
  Check,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowUp,
  Menu,
  PendingActions,
} from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  Badge,
} from "@mui/material";
import { Box } from "@mui/system";
import Moralis from "moralis";
import { useState } from "react";
import { useViewport } from "../viewport";
import { MyHitListCategory } from "./MyHitListCategory";
import { MyHitListItem } from "./MyHitListItem";
import { SectionHeader } from "./SectionHeader";
import { alpha, styled } from "@mui/material/styles";
import { ClassNames } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import metro from "../assets/MetroPhoto3.jpg";
import Ticker from "react-ticker";
import TimeAgo from "react-timeago";
import frenchStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { MyHitListModal } from "./MyHitListModal";

const formatter = buildFormatter(frenchStrings);
export function MyHitList({ toggleDrawer, user, count }) {
  const primaryColor = "white";
  const [achievementsLoaded, setAchievementsLoaded] = useState(false);
  const [achievements, setAchievements] = useState();
  const [categories, setCategories] = useState([]);
  const { width } = useViewport();
  const [myHitList, setMyHitList] = useState([]);
  const [myPoints, setPoints] = useState(0);
  const [line, showLine] = useState([]);
  const [newAchievement, setNewAchievement] = useState("");
  const [newAchievementPoints, setNewAchievementPoints] = useState();
  const [pending, setPending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState([]);
  const [congrats, showCongrats] = useState(false);
  const [open, setOpen] = useState({});

  const getAchievements = async () => {
    setAchievementsLoaded(true);
    getPendingAchievements();
    const AchievementObject = Moralis.Object.extend("Achievements");
    const AchievementsQuery = new Moralis.Query(AchievementObject);
    AchievementsQuery.ascending("category");
    AchievementsQuery.addAscending("point_value");
    AchievementsQuery.addAscending("name");
    const results = await AchievementsQuery.find();
    setAchievements(JSON.parse(JSON.stringify(results)));
    const results2 = await Moralis.Cloud.run("getFeed");
    setFeed(JSON.parse(JSON.stringify(results2)));
    console.log(JSON.parse(JSON.stringify(results2)));
    var arr = [];
    for (let i = 0; i < results.length; i++) {
      if (!arr.includes(JSON.parse(JSON.stringify(results[i])).category))
        arr.push(JSON.parse(JSON.stringify(results[i])).category);
    }
    setCategories(arr);
    var startingArray = new Array(arr.length);
    showLine(startingArray);
  };

  const getPendingAchievements = async () => {
    const AchievementObject = Moralis.Object.extend("AchievementsPending");
    const AchievementsQuery = new Moralis.Query(AchievementObject);
    AchievementsQuery.equalTo(
      "memberId",
      JSON.parse(JSON.stringify(user)).objectId
    );
    AchievementsQuery.ascending("dateCompleted");
    const results = await AchievementsQuery.find();
    setPending(JSON.parse(JSON.stringify(results)));
    console.log(results);
  };

  const addAchievements = async (achievement) => {
    var temp = Array.from(JSON.parse(JSON.stringify(myHitList)));
    temp.push(achievement);
    console.log(temp);
    console.log(achievement.point_value);
    var tempPoints = parseInt(myPoints) + achievement.point_value;
    setPoints(tempPoints);
    setMyHitList(temp);
  };

  const removeAchievement = (achievement) => {
    var temp = Array.from(JSON.parse(JSON.stringify(myHitList)));
    temp = temp.filter((a) => a.name !== achievement.name);
    console.log(temp);
    console.log(achievement.point_value);
    var tempPoints = parseInt(myPoints) - achievement.point_value;
    setPoints(tempPoints);
    setMyHitList(temp);
  };

  const adminAddAchievement = async (category) => {
    const params = {
      achievement: {
        category: category,
        name: newAchievement,
        points: newAchievementPoints.toString(),
        point_value: parseInt(newAchievementPoints),
        bonus: false,
      },
    };
    const cal = await Moralis.Cloud.run("adminAddAchievements", params);
    console.log(cal);
    setNewAchievement("");
    setNewAchievementPoints(0);
  };

  const adminRemoveAchievement = async (achievement) => {};

  if (!achievementsLoaded) {
    getAchievements();
  }
  console.log(JSON.parse(JSON.stringify(user)));
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        height: "100%",
        width: "100vw",
        maxWidth: "800pt",
        paddingBottom: "100pt",
      }}
    >
      <Grid
        item
        width="100%"
        style={{
          paddingTop: "10pt",
          paddingLeft: "10pt",
          maxWidth: "800pt",
          position: "fixed",
          background: "rgba(27,25,24,0.97)",
          zIndex: 2,
        }}
      >
        <Grid
          container
          direction="row"
          justifyItems="flex-start"
          alignItems="center"
          alignContent={"center"}
          wrap="nowrap"
        >
          <Badge badgeContent={count} variant="standard" color="success">
            <IconButton onClick={toggleDrawer(true)}>
              <Menu style={{ color: "white" }} />
            </IconButton>
          </Badge>
          {feed.length > 0 && (
            <Ticker offset={"run-in"}>
              {({ index }) => (
                <Grid container direction="row" wrap="nowrap">
                  <Typography noWrap>
                    {feed[index % feed.length]?.user?.customUsername
                      ? feed[index % feed.length]?.user?.customUsername
                      : feed[index % feed.length]?.user?.username}
                  </Typography>
                  <KeyboardDoubleArrowUp style={{ color: "green" }} />
                  <Typography
                    style={{ color: "green", paddingRight: "4pt" }}
                    noWrap
                  >
                    {feed[index % feed.length]?.points.toString()}
                  </Typography>
                  <Typography style={{ paddingRight: "0.5em" }} noWrap>
                    {" completed hit: " +
                      feed[index % feed.length]?.name.toString()}
                  </Typography>
                  <Typography style={{ paddingRight: "4.5em" }} noWrap>
                    <TimeAgo
                      date={new Date(feed[index % feed.length].dateCompleted)}
                      formatter={formatter}
                    />
                  </Typography>
                </Grid>
              )}
            </Ticker>
          )}
        </Grid>
      </Grid>
      <Typography
        style={{
          fontFamily: "Road_Rage",
          fontSize: "2em",
          marginBottom: "10pt",
          color: "#eb5454",
          paddingTop: "40pt",
        }}
      >
        The Hit List
      </Typography>
      <img
        src={metro}
        width="100%"
        style={{
          maxWidth: "400pt",
          maxHeight: "150pt",
          objectFit: "cover",
          borderRadius: "10pt",
          boxShadow: "0pt 0pt 3pt #eb5454",
          marginBottom: "10pt",
        }}
      />
      <Typography
        style={{
          color: "white",
          fontFamily: "Roboto, sans-serif",
          fontWeight: "bold",
          marginBottom: "10pt",
          maxWidth: "400pt",
        }}
      >
        What did you hit today?
        <br />
        Submit A List To Recieve
        <br />
        daily Metroflex Points!
      </Typography>
      {/* <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          position: "fixed",
          bottom: 0,
          background: "black",
          maxWidth: "800pt",
          borderTopLeftRadius: "50pt",
          borderTopRightRadius: "50pt",
          boxShadow: "0pt 0pt 3pt #eb5454",
        }}
        zIndex={1}
      >
        <IconButton
          onClick={
            showPending === false && pending.length > 0
              ? () => {
                  setShowPending(true);
                }
              : () => {}
          }
          style={{
            color:
              showPending === false && pending.length > 0
                ? "white"
                : "transparent",
          }}
        >
          <KeyboardArrowLeft />
        </IconButton>
         */}
      {/* <IconButton
          onClick={() => {
            setShowPending(false);
          }}
          style={{ color: showPending === false ? "transparent" : "white" }}
        >
          <KeyboardArrowRight />
        </IconButton> */}
      {/* </Grid> */}
      {loading && <CircularProgress />}
      {categories?.sort().map((category, x) => {
        console.log(category);
        if (open[x] == null) open[x] = false;
        return (
          <Box
            key={category.toString()}
            border={1}
            style={{
              padding: "10pt",
              height: "100%",
              width: "90vw",
              maxWidth: "700pt",
              borderRadius: "10pt",
              fontFamily: "Requiem, sans-serif",
              margin: "10pt",
            }}
          >
            <TableContainer>
              <Table size="small" sx={{ minWidth: "100%" }}>
                <MyHitListCategory
                  category={category}
                  user={user}
                  getAchievements={getAchievements}
                  opened={open}
                  index={x}
                  setOpen={setOpen}
                />
                <TableBody>
                  {open[x] ? (
                    achievements
                      .filter((achievement) => {
                        return (
                          JSON.parse(JSON.stringify(achievement)).category ===
                          category
                        );
                      })
                      .sort((a, b) => {
                        return a.name === "Scan In Daily Bonus" ? -1 : 1;
                      })
                      .map((achievement, i) => (
                        <MyHitListItem
                          key={achievement.objectId}
                          achievement={achievement}
                          i={i}
                          addAchievements={addAchievements}
                          removeAchievements={removeAchievement}
                          user={user}
                          getAchievements={getAchievements}
                        />
                      ))
                  ) : (
                    <TableRow
                      key={category + "show_more"}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: "white",
                          fontFamily: "Roboto, sans-serif",
                          fontSize: (width > 700 ? "x-" : "") + "large",
                        }}
                        onClick={() => {
                          const t = JSON.parse(JSON.stringify(open));
                          t[x] = true;
                          setOpen(t);
                        }}
                      >
                        {achievements.filter((achievement) => {
                          return (
                            JSON.parse(JSON.stringify(achievement)).category ===
                            category
                          );
                        }).length + " achievements"}{" "}
                        <KeyboardArrowDown />
                      </TableCell>
                    </TableRow>
                  )}
                  {(user?.id === "BcxIGp8FarTgazB7P4FvwEJ2" ||
                    user?.id === "XhfOK2iA0coZGFlBwHRHGGAe") && (
                    <TableRow
                      key={"adding" + x}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color: "white",
                          fontFamily: "Requiem, sans-serif",
                          fontSize: (width > 700 ? "x-" : "") + "large",
                        }}
                        colSpan={2}
                      >
                        {!line[x] ? (
                          <IconButton
                            onClick={() => {
                              var temp = Array.from(
                                JSON.parse(JSON.stringify(line))
                              );
                              temp[x] = true;
                              showLine(temp);
                            }}
                          >
                            <AddCircleOutline style={{ color: "white" }} />
                          </IconButton>
                        ) : (
                          <Grid container direction="column">
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                wrap="nowrap"
                                justifyContent="space-between"
                              >
                                <Input
                                  style={{
                                    color: "white",
                                    fontFamily: "Requiem, sans-serif",
                                  }}
                                  multiline
                                  inputStyle={{ color: "red" }} // Add this to your code
                                  placeholder="New Achievement"
                                  value={newAchievement}
                                  onChange={(newValue) => {
                                    newValue.preventDefault();
                                    setNewAchievement(newValue.target.value);
                                  }}
                                  autoCapitalize="none"
                                  autoCorrect={false}
                                  //leftIcon={<Icon name="email" size={20} color="#B3C1B3" />}
                                />
                                <Input
                                  type="number"
                                  placeholder="pt"
                                  multiline="false"
                                  value={newAchievementPoints}
                                  onChange={(newValue) => {
                                    newValue.preventDefault();
                                    if (parseInt(newValue.target.value) >= 0)
                                      setNewAchievementPoints(
                                        newValue.target.value
                                      );
                                    else if (newValue.target.value === "")
                                      setNewAchievementPoints("");
                                  }}
                                  style={{
                                    width: "50pt",
                                    fontFamily: "Requiem, sans-serif",
                                    borderColor: "white",
                                    color: "white",
                                    textAlign: "right",
                                  }}
                                />
                              </Grid>

                              <Grid item>
                                <Grid
                                  container
                                  direction="row"
                                  wrap="nowrap"
                                  justifyContent="space-around"
                                >
                                  <IconButton
                                    onClick={async (event) => {
                                      event.preventDefault();
                                      adminAddAchievement(category);
                                    }}
                                  >
                                    <Typography style={{ color: "white" }}>
                                      Add
                                    </Typography>
                                    <Check style={{ color: "green" }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={(event) => {
                                      event.preventDefault();
                                      var temp = Array.from(
                                        JSON.parse(JSON.stringify(line))
                                      );
                                      temp[x] = false;
                                      showLine(temp);
                                    }}
                                  >
                                    <Typography style={{ color: "white" }}>
                                      Cancel
                                    </Typography>
                                    <Cancel style={{ color: "red" }} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      })}{" "}
      <MyHitListModal
        pending={pending}
        myHitList={myHitList}
        myPoints={myPoints}
        setCategories={setCategories}
        setLoading={setLoading}
        user={user}
        setPoints={setPoints}
        showCongrats={showCongrats}
        setMyHitList={setMyHitList}
        getAchievements={getAchievements}
      />
    </Grid>
  );
}
