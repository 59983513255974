import { Typography } from "@mui/material";

export function SectionHeader({ title }) {
    return (
      <Typography
        style={{
          fontFamily: "Road_Rage",
          fontSize: "2em",
          marginBottom: "10pt",
          color: "#eb5454",
          maxWidth: "500pt",
        }}
        align="center"
      >
        {title}
      </Typography>
    );
  }