import { Menu } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { LandingPage } from "./LandingPage";

export function ErrorView({
  toggleDrawer,
  error,
  user,
  customUsername,
  ethAddress,
  login
}) {
  const [membership, setMembership] = useState();
  async function reload() {
    await login();
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        height: "100%",
        padding: "20pt",
      }}
    >
      <Grid
        item
        width="100%"
        style={{ paddingTop: "10pt", paddingLeft: "10pt" }}
      >
        <Grid container direction="row" justifyItems="flex-start">
          <IconButton onClick={toggleDrawer(true)}>
            <Menu style={{ color: "white" }} />
          </IconButton>
        </Grid>
      </Grid>
      <Typography>Please Log In to view {error}</Typography>
      <LandingPage
        user={user}
        customUsername={customUsername}
        setMembership={setMembership}
        ethAddress={ethAddress}
        login={()=>reload()}
      />
    </Grid>
  );
}
