import {
  CancelOutlined,
  Check, DoneAll,
  Edit,
  RemoveCircleOutline
} from "@mui/icons-material";
import { Grid, IconButton, Input, TableCell, TableRow } from "@mui/material";
import Moralis from "moralis";
import { useState } from "react";
import { useViewport } from "../viewport";

export function MyHitListItem({
  achievement,
  i,
  addAchievements,
  removeAchievements,
  user,
  getAchievements,
}) {
  const [clicked, setClicked] = useState();
  const [editing, setEditing] = useState(false);
  const [newName, setNewName] = useState("");
  const { width } = useViewport();

  const removeAchievementAdmin = async () => {
    const params = { name: achievement.name };
    const remove = await Moralis.Cloud.run("adminRemoveAchievements", params);
    getAchievements();
  };

  const editAchievementAdmin = async () => {
    const params = { oldName: achievement.name, newName: newName };
    const remove = await Moralis.Cloud.run("editAchievementAdmin", params);
    getAchievements();
  };

  return (
    <TableRow
      key={achievement.name + i}
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
    >
      <TableCell
        component="th"
        scope="row"
        style={{
          color: clicked ? "#eb5454" : "white",
          fontFamily: "Roboto, sans-serif",
          fontWeight: "bold",
          textDecoration: clicked && !editing ? "white line-through" : "",
          fontSize: (width > 700 ? "x-" : "") + "large",
        }}
        onClick={
          editing
            ? () => {}
            : () => {
                clicked
                  ? removeAchievements(achievement)
                  : addAchievements(achievement);
                setClicked(!clicked);
              }
        }
      >
        {!editing ? (
          <div>
            {JSON.parse(JSON.stringify(achievement)).name}
            {clicked && <DoneAll style={{ fontSize: "medium", marginLeft: "10pt", }} />}
            {(user?.id === "BcxIGp8FarTgazB7P4FvwEJ2" ||
              user?.id === "XhfOK2iA0coZGFlBwHRHGGAe") && (
              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  setEditing(true);
                }}
              >
                <Edit style={{ color: "white" }} />
              </IconButton>
            )}
          </div>
        ) : (
          <Grid container direction="row">
            <Input
              style={{
                color: "white",
                fontFamily: "Roboto, sans-serif",
              }}
              multiline
              placeholder={achievement.name}
              value={newName}
              onChange={(newValue) => {
                newValue.preventDefault();
                setNewName(newValue.target.value);
              }}
              onSubmit={(event) => {
                event.preventDefault();
                setEditing(false);
                editAchievementAdmin();
              }}
              autoCapitalize="none"
              //leftIcon={<Icon name="email" size={20} color="#B3C1B3" />}
            />
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                setEditing(false);
                editAchievementAdmin();
              }}
            >
              <Check style={{ color: "white" }} />
            </IconButton>
          </Grid>
        )}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{
          color: "gold",
          fontFamily: "Roboto, sans-serif",
          fontWeight: "bold",
          fontSize: (width > 700 ? "x-" : "") + "large",
        }}
      >
        {JSON.parse(JSON.stringify(achievement)).points + "pt"}
        {(user?.id === "BcxIGp8FarTgazB7P4FvwEJ2" ||
          user?.id === "XhfOK2iA0coZGFlBwHRHGGAe") &&
          (editing ? (
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                setEditing(false);
              }}
            >
              <CancelOutlined style={{ color: "white" }} />
            </IconButton>
          ) : (
            <div>
              <IconButton
                onClick={(event) => {
                  event.preventDefault();
                  removeAchievementAdmin(achievement);
                }}
              >
                <RemoveCircleOutline style={{ color: "red" }} />
              </IconButton>
            </div>
          ))}
      </TableCell>
    </TableRow>
  );
}
