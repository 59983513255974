import styled from "@emotion/styled";
import {
  Cancel,
  Check,
  DoneAll,
  Error,
  Visibility,
  HandshakeIcon,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Container,
  Grid,
  Input,
  ModalUnstyled,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Moralis from "moralis";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../App.css";
import Logo from "../assets/logo.png";
import Wallet from "../assets/wallet.png";
import { useViewport } from "../viewport";
import { ConnectHelp } from "./ConnectHelp";
import "./styles.css";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    background: "grey",
    borderColor: "#eb5454",
    borderTopLeftRadius: "5pt",
    borderBottomLeftRadius: "5pt",
    "& fieldset": {
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
    "&:hover fieldset": {
      borderColor: "#eb5454",
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#eb5454",
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
  },
});
const style = {
  width: 400,
  bgcolor: "black",
  p: 2,
  px: 4,
  pb: 3,
  color: "white",
  borderRadius: "10pt",
};
export function LandingPage({
  user,
  customUsername,
  setMembership,
  ethAddress,
  login,
  bonusCount,
}) {
  const { width } = useViewport();
  let history = useNavigate();
  const [modal, showModal] = useState(false);
  const handleOpen = () => showModal(true);
  const handleClose = () => showModal(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [input, setInput] = useState();
  const [createMode, setCreateMode] = useState(true);
  const [newUsername, setNewUsername] = useState();
  const [cUsername, setCustomUsername] = useState();
  const handleSubmit = (event) => {
    event.preventDefault();
    const result = input;
    if (result.length !== 12) return;
    console.log(result);
    try {
      setMembership(result);
      const userObject = Moralis.User.current();
      userObject.set("phoneNumber", result);
      userObject.save();
    } catch (err) {
      console.log(err);
    }
  };
  const switchToCreate = (event) => {
    event.preventDefault();
    setCreateMode(true);
  };
  const handleLogin = async (event) => {
    event.preventDefault();
    if (userName.length > 2 && password.length > 7) {
      try {
        const user = await Moralis.User.logIn(userName, password);
        setSuccess(true);
        await user.fetch();
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    } else {
      setError(
        "Username must be at least 3 characters.\nPassword must be at least 8 characters."
      );
    }
  };
  const handleSignup = async (event) => {
    event.preventDefault();
    if (userName.length > 2 && password.length > 7) {
      try {
        const user = new Moralis.User();
        user.set("username", userName);
        user.set("password", password);
        await user.signUp();
        await user.fetch();
        setSuccess(true);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    } else {
      setError(
        "Username must be at least 3 characters.\nPassword must be at least 8 characters."
      );
    }
  };
  const handleUsernameSubmit = async (event) => {
    event.preventDefault();
    const result = newUsername;
    if (result.length < 1) return;
    console.log(result);
    try {
      setCustomUsername(result);
      const userObject = await Moralis.User.currentAsync();
      userObject.set("customUsername", result);
      await userObject.save();
      login();
    } catch (err) {
      console.log(err);
    }
  };
  console.log(user);
  return (
    <div style={{ paddingTop: "80pt" }}>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modal}
        onClose={handleClose}
        onBackdropClick={handleClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyItems={"space-between"}
          >
            {success ? (
              <div>
                <Grid container direction="column" alignItems={"center"}>
                  <Typography
                    style={{
                      color: "#eb5454",
                      fontFamily: "Requiem, sans-serf",
                    }}
                    align="center"
                  >
                    Welcome to the next generation Metroflex
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontFamily: "Roboto, sans-serf",
                      width: "100%",
                    }}
                    align="left"
                  >
                    1) Add this page to your bookmarks for easy access.
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontFamily: "Roboto, sans-serf",
                      width: "100%",
                    }}
                    align="left"
                  >
                    2) Setup your profile in My Profile for BONUS points!
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontFamily: "Roboto, sans-serf",
                      width: "100%",
                    }}
                    align="left"
                  >
                    3) Submit your next workout to the hitlist
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      fontFamily: "Roboto, sans-serf",
                      width: "100%",
                    }}
                    align="left"
                  >
                    4) Interact in our Forum
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={(event) => {
                      event.preventDefault();
                      setSuccess(false);
                      login();
                      showModal(false);
                    }}
                    style={{
                      background: "#eb5454",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    Get Started!
                  </Button>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid container direction="row" justifyContent="center">
                  <img
                    src={Logo}
                    width="90%"
                    style={{ maxWidth: "400pt", maxHeight: "500pt" }}
                  />
                </Grid>
                <Typography style={{ fontSize: "x-large" }} align="center">
                  {createMode ? "Create an account" : "Login"}
                </Typography>
                {error && (
                  <Typography style={{ fontWeight: "bold", color: "#eb5454" }}>
                    {error.toString()}
                  </Typography>
                )}
                <CssTextField
                  value={userName}
                  variant="filled"
                  onChange={(value) =>
                    setUsername(value.target.value.replace(/\s+/g, ""))
                  }
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    endAdornment:
                      createMode &&
                      (userName.length > 2 ? (
                        <Check style={{ color: "green" }} />
                      ) : (
                        <Grid
                          container
                          direction="row"
                          justifyContent={"flex-end"}
                        >
                          <Typography
                            style={{ color: "#eb5454", fontWeight: "bold" }}
                          >
                            {userName.length - 3}
                          </Typography>
                        </Grid>
                      )),
                  }}
                  style={{
                    background: "rgb(38,38,38)",
                    width: "100%",
                    marginBottom: "5pt",
                  }}
                  autoComplete="off"
                  placeholder="Username"
                />
                <CssTextField
                  value={password}
                  variant="filled"
                  onChange={(value) =>
                    setPassword(value.target.value.replace(/\s+/g, ""))
                  }
                  type="password"
                  style={{
                    background: "rgb(38,38,38)",
                    width: "100%",
                    marginBottom: "10pt",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  fullWidth
                  onSubmit={(event) =>
                    createMode ? handleSignup(event) : handleLogin()
                  }
                  InputProps={{
                    endAdornment:
                      createMode &&
                      (password.length > 7 ? (
                        <Check style={{ color: "green" }} />
                      ) : (
                        <Grid
                          container
                          direction="row"
                          justifyContent={"flex-end"}
                        >
                          <Typography
                            style={{ color: "#eb5454", fontWeight: "bold" }}
                          >
                            {password.length - 8}
                          </Typography>
                        </Grid>
                      )),
                  }}
                  placeholder="Password"
                ></CssTextField>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    background: "#eb5454",
                    marginBottom: "10pt",
                    textTransform: "none",
                    fontSize: "large",
                  }}
                  onClick={(event) =>
                    createMode ? handleSignup(event) : handleLogin(event)
                  }
                >
                  {createMode ? "Create Account" : "Login"}
                </Button>
                <Button
                  variant="text"
                  fullWidth
                  style={{
                    textTransform: "none",
                  }}
                  onClick={(event) =>
                    createMode ? setCreateMode(false) : switchToCreate(event)
                  }
                >
                  {createMode ? (
                    <Grid container direction="column">
                      <Typography style={{ color: "grey", fontSize: "large" }}>
                        Already have an account?
                      </Typography>
                      <Typography
                        style={{ color: "#eb5454", fontSize: "large" }}
                      >
                        Click here to Login
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container direction="column">
                      <Typography style={{ color: "grey" }}>
                        Don't have an account?
                      </Typography>
                      <Typography
                        style={{ color: "#eb5454", fontSize: "large" }}
                      >
                        Create an account.
                      </Typography>
                    </Grid>
                  )}
                </Button>
              </div>
            )}
          </Grid>
        </Box>
      </StyledModal>
      {!user ? (
        <Grid item>
          {/* <Grid
          container
          direction="column"
          justifyContent="center"
          justifyItems="center"
          alignContent="center"
          alignSelf="center"
          style={{
            backgroundImage: "url(" + Wallet + ")",
            width: width > 600 ? "300pt" : "200pt",
            height: width > 600 ? "300pt" : "200pt",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            paddingTop: "30pt",
            paddingRight: "20pt",
            margin: "auto",
          }}
        > */}
          {/* <img
            src={Logo}
            height={width > 600 ? "100pt" : "80pt"}
            width={width > 600 ? "140pt" : "120pt"}
            style={{ margin: "auto" }}
          /> */}

          <img src={Logo} width="90%" style={{ maxWidth: "400pt" }} />
          <Typography
            style={{ fontFamily: "Doctor Glitch", fontSize: "1.8em" }}
          >
            NFTs
          </Typography>
          {/*</Grid>*/}
          <Typography
            style={{
              fontSize: "1.6em",
              fontWeight: "bold",
              fontFamily: "Roboto, sans-serf",
              marginTop: "20pt",
            }}
          >
            Start Today!
          </Typography>
          <Grid container direction="column" justifyContent="center">
            <Typography
              style={{
                fontSize: "x-large",
                color: "#eb5454",
                fontFamily: "Road_Rage",
              }}
            >
              CONNECT
            </Typography>
            <Typography
              style={{
                fontFamily: "Roboto, sans-serf",
                fontSize: "medium",
              }}
            >
              {"Use a Crypto Wallet or Username"}
            </Typography>
            <ConnectHelp handleOpen={handleOpen} />
          </Grid>
          <Grid container direction="column" justifyContent="center">
            <Typography
              style={{
                fontSize: "x-large",
                color: "#eb5454",
                fontFamily: "Road_Rage",
              }}
            >
              {"REGISTER"}
            </Typography>
            <Typography
              style={{
                fontFamily: "Roboto, sans-serf",
                fontSize: "medium",
              }}
            >
              {"Create a Username."}
            </Typography>
          </Grid>

          <Grid container direction="column" justifyContent="center">
            <Typography
              style={{
                fontSize: "x-large",
                color: "#eb5454",
                fontFamily: "Road_Rage",
              }}
            >
              {"COLLECT"}
            </Typography>
            <Typography
              style={{
                fontFamily: "Roboto, sans-serf",
                fontSize: "medium",
              }}
            >
              {"Build Collection."}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <div>
          <img src={Logo} width="90%" style={{ maxWidth: "400pt" }} />
          <Grid container direction="row" justifyContent="center">
            {/* <Typography style={{ fontSize: "xxx-large" }}>MetroFlex</Typography> */}
            <Typography
              style={{ fontFamily: "Doctor Glitch", fontSize: "2em" }}
            >
              NFTs
            </Typography>
          </Grid>
        </div>
      )}
      {user ? (
        <Box
          border={1}
          style={{
            borderRadius: "5pt",
            borderColor: "rgba(255, 255, 255, 0.3)",
            margin: "20pt",
          }}
        >
          <Container>
            {ethAddress && (
              <div>
                <Typography
                  style={{
                    fontFamily: "Roboto, sans-serf",
                    fontSize: "0.7em",
                  }}
                >
                  {"Using Address:"}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto, sans-serf",
                    fontSize: "0.7em",
                    marginBottom: "1s0pt",
                  }}
                >
                  {ethAddress?.toString()}
                </Typography>
              </div>
            )}

            {(customUsername || cUsername) && (
              // (
              //   <Grid
              //     container
              //     direction="row"
              //     alignItems="center"
              //     justifyContent="center"
              //     style={{ marginTop: "20pt" }}
              //   >
              //     <Cancel style={{ color: "red", marginBottom: "10pt" }} />
              //     <Typography
              //       style={{
              //         fontFamily: "Roboto, sans-serf",
              //         fontSize: "large",
              //         fontWeight: "bold",
              //       }}
              //     >
              //       {"No Name Submitted."}
              //     </Typography>
              //   </Grid>
              // ) : (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "10pt" }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto, sans-serf",
                    fontSize: "large",
                    fontWeight: "bold",
                  }}
                >
                  {"Welcome " + (customUsername ? customUsername : cUsername)}
                </Typography>
              </Grid>
            )}
            {/* 
            {!(customUsername || cUsername) && (
              <Grid container direction="row" justifyContent="center">
                <Box
                  border={1}
                  style={{
                    borderRadius: "5pt",
                    padding: "5pt",
                    margin: 0,
                  }}
                >
                  <Input
                    type="text"
                    maxRows={1}
                    style={{ color: "white" }}
                    onSubmit={handleUsernameSubmit}
                    value={newUsername}
                    placeholder="Your Name"
                    onChange={(event) => {
                      event.preventDefault();
                      if (event.target.value.length < 13) {
                        setNewUsername(event.target.value);
                      }
                    }}
                  />
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={handleUsernameSubmit}
                    style={{
                      borderColor: "transparent",
                      borderRadius: "5pt",
                      textTransform: "none",
                      color: "white",
                      fontFamily: "Roboto, sans-serf",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            )} */}
            <Grid
              container
              direction="column"
              justifyContent={"center"}
              style={{ maxWidth: "400pt", width: "75%", margin: "auto" }}
            >
              {(cUsername || customUsername) && (
                <Badge
                  badgeContent={bonusCount}
                  variant="standard"
                  color="success"
                >
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      background: "#eb5454",
                      color: "white",
                      textTransform: "none",
                    }}
                    onClick={() => history("/myprofile")}
                    fullWidth
                  >
                    My Profile
                  </Button>
                </Badge>
              )}
              <br />
              {(cUsername || customUsername) && (
                <Button
                  variant="contained"
                  style={{
                    background: "#eb5454",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => history("/hitlist")}
                >
                  The Hitlist
                </Button>
              )}
              <br />
              {(cUsername || customUsername) && (
                <Button
                  variant="contained"
                  style={{
                    background: "#eb5454",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => history("/leaderboard")}
                >
                  Leaderboard
                </Button>
              )}
              <br />
              {(cUsername || customUsername) && (
                <Button
                  variant="contained"
                  style={{
                    background: "#eb5454",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => history("/raffle")}
                >
                  Raffle
                </Button>
              )}
              <br />
              {
                <Button
                  variant="outlined"
                  style={{
                    borderColor: "#eb5454",
                    color: "white",
                    textTransform: "none",
                    marginTop: "10pt",
                    marginBottom: "10pt",
                  }}
                  onClick={async () => {
                    await Moralis.User.logOut();
                    login();
                    setSuccess(false);
                  }}
                >
                  Log out
                </Button>
              }
            </Grid>
          </Container>
        </Box>
      ) : (
        <div />
      )}
    </div>
  );
}
