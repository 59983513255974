import {
    Container,
    Grid,
    IconButton,
    Modal,
    ModalUnstyled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import { useViewport } from "../viewport";
  import { SectionHeader } from "./SectionHeader";
  import "../App.css";
  import { useState } from "react";
  import METROFLEX from "../assets/NFT_Promp1.png";
  import {
    Check,
    Close,
    Collections,
    Leaderboard,
    LocalActivity,
    Menu,
    WorkspacePremium,
  } from "@mui/icons-material";
  import DefaultAvatar from "../assets/defaultAvatar.png";
  import DefaultAvatarF from "../assets/defaultAvatarF.png";
  import styled from "@emotion/styled";
  const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
  `;
  
  const Backdrop = styled("div")`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
  `;
  
  const style = {
    bgcolor: "black",
    p: 2,
    px: 4,
    pb: 3,
    pt: 8,
    color: "white",
    border: "2px solid #000",
  };

export function UserProfileModal({selectedUser, handleClose, modal}){
    const { width } = useViewport();
    return <StyledModal
    aria-labelledby="unstyled-modal-title"
    aria-describedby="unstyled-modal-description"
    open={modal}
    onClose={handleClose}
    onBackdropClick={handleClose}
    BackdropComponent={Backdrop}
  >
    <Box sx={style} style={{ overflow: width < 600 ? "scroll" : "" }}>
      <Grid container direction="column" alignItems="center">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <IconButton onClick={handleClose}>
            <Close style={{ color: "black" }} />
          </IconButton>
          <img
            src={
              selectedUser?.image !== undefined &&
              selectedUser?.image !== "default"
                ? selectedUser.image
                : DefaultAvatar
            }
            width="200pt"
            height="200pt"
            style={{ borderRadius: "50%", objectFit: "cover" }}
          ></img>
          <IconButton onClick={handleClose}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </Grid>

        <Typography
          style={{
            marginTop: "20pt",
            marginBottom: "10pt",
            fontSize: "x-large",
            color: "#eb5454",
            fontFamily: "Requiem, sans-serif",
          }}
          align="left"
        >
          {selectedUser?.name}
        </Typography>
        <Typography
          style={{
            fontFamily: "Requiem, sans-serif",
            fontSize: "large",
          }}
        >
          {selectedUser?.bio}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-end"
          style={{ maxWidth: "300pt", margin: "auto" }}
        >
          <Grid item>
            <Box
              style={{
                borderRadius: "5pt",
                padding: "5pt",
                borderColor: "#eb5454",
                margin: "auto",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  {"Points"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Pangolin, cursive",
                  }}
                >
                  {selectedUser?.points}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{
                borderRadius: "5pt",
                padding: "5pt",
                borderColor: "#eb5454",
                margin: "auto",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  {"Mural"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Pangolin, cursive",
                  }}
                >
                  {(selectedUser?.murals ? selectedUser?.murals : "0") +
                    "/12"}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{
                borderRadius: "5pt",
                padding: "5pt",
                borderColor: "#eb5454",
                margin: "auto",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  {"Tickets"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Pangolin, cursive",
                  }}
                >
                  {selectedUser?.tickets ? selectedUser?.tickets : "0"}
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Typography
          style={{
            marginTop: "30pt",
            marginBottom: "10pt",
            fontSize: "x-large",
            color: "#eb5454",
            fontFamily: "Requiem, sans-serif",
          }}
          align="left"
        >
          Personal Records
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-end"
          style={{ maxWidth: "300pt", margin: "auto" }}
        >
          <Grid item>
            <Box
              style={{
                borderRadius: "5pt",
                padding: "5pt",
                borderColor: "#eb5454",
                margin: "auto",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  {"Bench PR"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Pangolin, cursive",
                  }}
                >
                  {(selectedUser?.benchPR ? selectedUser?.benchPR : "0") +
                    " lbs."}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{
                borderRadius: "5pt",
                padding: "5pt",
                borderColor: "#eb5454",
                margin: "auto",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  {"Squat PR"}
                </Typography>

                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Pangolin, cursive",
                  }}
                >
                  {(selectedUser?.squatPR ? selectedUser?.squatPR : "0") +
                    " lbs."}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{
                borderRadius: "5pt",
                padding: "5pt",
                borderColor: "#eb5454",
                margin: "auto",
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Requiem, sans-serif",
                  }}
                >
                  {"Mile PR"}
                </Typography>
                <Typography
                  style={{
                    fontSize: "large",
                    fontFamily: "Pangolin, cursive",
                  }}
                >
                  {selectedUser?.milePR
                    ? parseInt(
                        Math.floor(selectedUser?.milePR / 60).toFixed(0)
                      ).toString() +
                      ":" +
                      (parseInt(
                        selectedUser?.milePR -
                          parseInt(
                            Math.floor(selectedUser?.milePR / 60).toFixed(0)
                          ) *
                            60
                      ) < 10
                        ? "0"
                        : "") +
                      parseInt(
                        selectedUser?.milePR -
                          parseInt(
                            Math.floor(selectedUser?.milePR / 60).toFixed(0)
                          ) *
                            60
                      ).toString()
                    : "0:00"}
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Box
            style={{
              borderRadius: "5pt",
              padding: "5pt",
              borderColor: "#eb5454",
              margin: "auto",
            }}
          >
            <Grid container direction="column" alignItems="center">
              <Typography
                style={{
                  fontSize: "large",
                  fontFamily: "Requiem, sans-serif",
                }}
              >
                {"Deadlift PR"}
              </Typography>
              <Typography
                style={{
                  fontSize: "large",
                  fontFamily: "Pangolin, cursive",
                }}
              >
                {(selectedUser?.deadliftPR
                  ? selectedUser?.deadliftPR
                  : "0") + " lbs."}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  </StyledModal>
}