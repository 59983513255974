import NFT1 from "../assets/NFT_Promp1.png";
import NFT2 from "../assets/NFT_Promp3.png";
import NFT3 from "../assets/NFT_Promp2.png";
import NFT4 from "../assets/NFT_Promp4.png";
import NFT5 from "../assets/NFT_Promp5.png";
import NFT6 from "../assets/NFT_Promp7.png";
import NFT7 from "../assets/NFT_Promp6.png";
import NFT8 from "../assets/NFT_Promp9.png";
import NFT9 from "../assets/NFT_Promp0.png";
import NFT10 from "../assets/NFT_Promp8.png";
import NFT11 from "../assets/NFT_Promp10.png";
import NFT12 from "../assets/NFT_Promp11.png";

import { useViewport } from "../viewport";
import { Container, Grid, Typography } from "@mui/material";
import { Lock } from "@mui/icons-material";

export function NFTView({ index, claimable, claimed }) {
  const { width } = useViewport();
  const img =
    index === 1
      ? NFT1
      : index === 2
      ? NFT2
      : index === 3
      ? NFT3
      : index === 4
      ? NFT4
      : index === 5
      ? NFT5
      : index === 6
      ? NFT6
      : index === 7
      ? NFT7
      : index === 8
      ? NFT8
      : index === 9
      ? NFT9
      : index === 10
      ? NFT10
      : index === 11
      ? NFT11
      : NFT12;
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        style={{
          height: width < 600 ? "75pt" : "200pt",
          width:
            index === 1
              ? width < 600
                ? "200pt"
                : "500pt"
              : width < 600
              ? "75pt"
              : "200pt",
          backgroundImage: "url(" + img + ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: "10pt",
          overflow: "hidden",
          filter: claimed === undefined || claimed === false ? "blur(4px)" : "",
          WebkitFilter:
            claimed === undefined || claimed === false ? "blue(4px)" : "",
        }}
        justifyContent={claimed ? "flex-start" : "flex-end"}
        alignContent={claimed ? "flex-end" : ""}
      ></Grid>

      {!claimed && (
        <Typography
          style={{
            fontFamily: "Eraser Regular",
            fontSize: "large",
            // marginBottom: "10pt",
            color: "#eb5454",
            background: claimed
              ? "rgba(0,0,0, 0.8)"
              : !claimable
              ? "transparent"
              : "rgba(0, 0, 0, 0.95)",
            width: "80%",
            maxWidth: "200pt",
            borderRadius: "5pt",
          }}
          className="bg-text"
        >
          {claimable === null ? (
            ""
          ) : !claimable ? (
            <Lock style={{ color: "black" }} />
          ) : index === 12 ? (
            "Claim"
          ) : (
            "100 pts"
          )}
        </Typography>
      )}
    </div>
  );
}
