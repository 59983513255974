import { Container, Grid, Typography } from "@mui/material";
import { SectionHeader } from "./SectionHeader";
import photo from "../assets/MetroPhoto.jpg";
import Metro from "../assets/MetroBlackLogo.png";
import photo2 from "../assets/MetroPhoto2.jpg";
import photo3 from "../assets/MetroPhoto3.jpg";
import photo4 from "../assets/MetroPhoto4.jpg";
import photo5 from "../assets/MetroPhoto5.jpeg";
import photo6 from "../assets/MetroPhoto6.jpg";
import photo11 from "../assets/MetroPhoto11.jpg";
import promo from "../assets/NFT_Promp.png";
import Logo from "../assets/NFT_Promp1.png";
import demo from "../assets/iphoneMock0.png";
import demo2 from "../assets/iphoneMock1.png";
import demo3 from "../assets/iphoneMock3.png";
import demo4 from "../assets/iphoneMock4.png";
import demo5 from "../assets/iphoneMock5.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

export function AboutView() {
  return (
    <Container
      style={{
        overflow: "clip",
        backgroundColor: "rgba(27,25,24, 0.97)",
        maxWidth: "100vw",
        padding: 0,
      }}
    >
      <Grid container direction="column" alignItems="center">
        {" "}
        {/* <img
          src={Metro}
          width="100%"
          style={{
            maxWidth: "400pt",
            objectFit: "cover",
          }}
        /> */}
        <Typography
          style={{
            fontFamily: "Bebas Neue, cursive",
            fontSize: "large",
            background: "#eb5454",
            borderTopLeftRadius: "5pt",
            borderTopRightRadius: "5pt",
          }}
        >
          12551 E Carson St, Hawaiian Gardens, CA 90716
        </Typography>
        {/* <Grid
          container
          direction="row"
          wrap="nowrap"
          style={{ maxHeight: "250pt", overflow: "scroll", overflowY: "clip" }}
        > */}
        {/*<img
            src={photo4}
            width="100%"
            style={{
              maxWidth: "500pt",
              objectFit: "cover",
            }}
          />
        </Grid> */}
        {/* <Grid container direction="column" alignItems="center">
          <Grid item>
            <SectionHeader title={"DAILY WORKOUTS"} />
            <img
              src={demo}
              width=""
              style={{ maxWidth: "300pt", maxHeight: "300pt" }}
            />
          </Grid>
          <Grid item>
            <SectionHeader title={"Climb the leaderboard"} />
            <img
              src={demo4}
              style={{ maxWidth: "300pt", maxHeight: "300pt" }}
            />
          </Grid>{" "}
          <Grid item>
            <SectionHeader title={"Unlock the Mural"} />
            <img
              src={demo3}
              style={{ maxWidth: "300pt", maxHeight: "300pt" }}
            />
          </Grid>
        </Grid> */}
        {/* <SectionHeader title={"NFT Rewards"} />
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "Roboto, sans-serif",
            fontSize: "large",
            maxWidth: "450pt",
          }}
        >
          A reward program that applies gamification with fitness. Earn points
          for attending events, purchases, workouts, and more!
        </Typography>
        <img
          src={promo}
          width="60%"
          style={{
            maxWidth: "300pt",
            boxShadow: "0px 0px 25px white",
            borderRadius: "10pt",
            marginTop: "10pt",
            marginBottom: "10pt",
          }}
        />
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "Roboto, sans-serif",
            fontSize: "large",
            marginTop: "5pt",
            paddingBottom: "5pt",
            maxWidth: "400pt",
          }}
        >
          We are proud to provide our Metroflex community with iconic artwork of
          our journey.
        </Typography>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "Roboto, sans-serif",
            fontSize: "large",
            marginTop: "5pt",
            paddingBottom: "5pt",
            maxWidth: "400pt",
          }}
        >
          These rare digital assets can be earned for free using Metroflex
          points!
        </Typography> */}
        {/* <SectionHeader title={"Community Forum"} />
        <img src={demo5} style={{ maxWidth: "300pt", maxHeight: "300pt" }} /> */}
      </Grid>
      <img
        src={Logo}
        width="100%"
        style={{ marginTop: "20pt", maxWidth: "500pt" }}
      ></img>
    </Container>
  );
}
