import {
  Container,
  Grid,
  IconButton,
  Modal,
  ModalUnstyled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useViewport } from "../viewport";
import { SectionHeader } from "./SectionHeader";
import "../App.css";
import { useState } from "react";
import METROFLEX from "../assets/NFT_Promp1.png";
import {
  Check,
  Close,
  Collections,
  Leaderboard,
  LocalActivity,
  Menu,
  WorkspacePremium,
} from "@mui/icons-material";
import DefaultAvatar from "../assets/defaultAvatar.png";
import DefaultAvatarF from "../assets/defaultAvatarF.png";
import styled from "@emotion/styled";
import { UserProfileModal } from "./UserProfileModal";
import MetroBaseball from "../assets/MetroBaseball.png";
import { TopNavBar } from "./TopNavBar";

function createData(
  name,
  image,
  points,
  murals,
  tickets,
  benchPR,
  squatPR,
  milePR,
  deadliftPR,
  bio
) {
  return {
    name,
    image,
    points,
    murals,
    tickets,
    benchPR,
    squatPR,
    milePR,
    deadliftPR,
    bio,
  };
}

export function LeaderboardView({ isHomePage, toggleDrawer }) {
  const { width } = useViewport();
  const [leaderboardLoaded, setLeaderboardLoaded] = useState(false);
  const [leaderboard, setLeaderboard] = useState();
  const [modal, showModal] = useState(false);
  const [selectedUser, setUser] = useState();
  const handleOpen = (user) => {
    setUser(user);
    showModal(true);
  };
  const handleClose = () => showModal(false);

  const getUserData = async () => {
    setLeaderboardLoaded(true);
    try {
      fetch(
        "https://df7f3ghoensc.usemoralis.com:2053/server/functions/getLeaderboard?_ApplicationId=6GQoJjklMnsK2skhUTTa2EyGgvXl59gGB2mhzekZ"
      )
        .then((result) => result.json())
        .then((data) => {
          var rows = data.result?.map((user, i) =>
            createData(
              user.customUsername
                ? user.customUsername.slice(0, 12) +
                    (user.customUsername.length > 11 ? "..." : "")
                : user.ethAddress
                ? user.ethAddress.slice(0, 9) + "..."
                : user.username.slice(0, 12) +
                  (user.username.length > 11 ? "..." : ""),
              user.image,
              user.points,
              user.murals,
              user.tickets,
              user.benchPR,
              user.squatPR,
              user.milePR,
              user.deadliftPR,
              user.bio
            )
          );
          for (var x = rows?.length; x < 10; x++) {
            rows.push(createData("unclaimed", 0, 0));
          }
          setLeaderboard(rows);
        });
    } catch (err) {
      console.log(err);
    }
  };

  if (!leaderboardLoaded) getUserData();
  console.log(leaderboard);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      alignContent="center"
      justifyItems="center"
      justifySelf="center"
      alignSelf="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        paddingTop: "20pt",
        paddingBottom: "40pt",
        width: "100vw",
        maxWidth: "900pt",
        margin: "auto",
      }}
    >
      {!isHomePage && (
        <Grid item style={{ width: "100%" }}>
          <TopNavBar toggleDrawer={toggleDrawer} />
        </Grid>
      )}

      <Typography
        style={{
          fontFamily: "Road_Rage",
          fontSize: "2em",
          marginTop: "10pt",
          marginBottom: "10pt",
          color: "#eb5454",
        }}
      >
        Leaderboard
      </Typography>
      {!isHomePage && (
        <img
          src={METROFLEX}
          width="100%"
          style={{
            maxWidth: "400pt",
            maxHeight: "150pt",
            objectFit: "cover",
            borderRadius: "10pt",
            boxShadow: "0pt 0pt 3pt #eb5454",
            marginBottom: "10pt",
          }}
        />
      )}
      <Typography
        style={{
          color: "rgba(243, 243, 243, 0.97)",
          fontFamily: "Roboto, sans-serif",
          fontWeight: "bold",
          fontSize: "large",
          maxWidth: "400pt",
          marginBottom: "10pt",
          padding: "10pt",
        }}
      >
        Hard work pays off, make your way to the top to receive exclusive
        rewards & prizes!
      </Typography>
      <UserProfileModal
        handleClose={handleClose}
        selectedUser={selectedUser}
        modal={modal}
      />
      <Box
        style={{
          padding: "10pt",
          height: "100%",
          width: "90%",
          maxWidth: "500pt",
          borderRadius: "10pt",
          fontFamily: "Requiem, sans-serif",
        }}
      >
        <TableContainer>
          <Table size="small" sx={{ minWidth: "100%" }}>
            <TableBody>
              {leaderboard?.map((row, i) => (
                <TableRow
                  key={row.name + "i:" + i + "tablerow"}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color:
                        i === 0
                          ? "gold"
                          : i === 1
                          ? "#eb5454"
                          : i === 2
                          ? "#eb5454"
                          : "white",
                      fontFamily: "Requiem, sans-serif",
                      fontSize: (width > 700 ? "x-" : "") + "large",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {i + 1}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleOpen(row);
                    }}
                    component="th"
                    scope="row"
                    style={{
                      color: "white",
                      fontFamily: "Requiem, sans-serif",
                      fontSize: "x-large",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{
                        width: "100%",
                        maxWidth: "290pt",
                        marginTop: "5pt",
                        marginBottom: "5pt",
                        maxHeight: "80pt",
                      }}
                      wrap="nowrap"
                    >
                      {row.image !== null && row.image !== "default" ? (
                        <img
                          src={row.image}
                          style={{
                            borderRadius: "50%",
                            objectFit: "cover",
                            height: "50pt",
                            minWidth: "50pt",
                            maxWidth: "50pt",
                          }}
                        />
                      ) : (
                        <img
                          src={DefaultAvatar}
                          height="60pt"
                          style={{ borderRadius: "50%" }}
                        />
                      )}
                      <Grid
                        container
                        direction="column"
                        style={{ marginLeft: "20pt" }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            fontFamily: "Requiem, sans-serif",
                            fontSize: "x-large",
                            maxWidth: "100%",
                            overflow: "auto",
                          }}
                        >
                          {row.name === "" ? "NoName" : row.name}
                        </Typography>
                        <Grid item>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              width: "100%",
                              maxWidth: "290pt",
                              fontFamily: "Roboto, sans-serif",
                              fontWeight: "bold",
                              fontSize: width > 600 ? "x-large" : "medium",
                            }}
                            wrap="nowrap"
                          >
                            <Grid item>
                              <Collections
                                style={{
                                  color: "#eb5454",
                                  fontSize: width > 600 ? "x-large" : "large",
                                }}
                              />
                              {row.murals ? row.murals : "0"}
                            </Grid>
                            <Grid item>
                              <LocalActivity
                                style={{
                                  color: "#eb5454",
                                  fontSize: width > 600 ? "x-large" : "large",
                                }}
                              />
                              {row.tickets ? row.tickets : "0"}
                            </Grid>
                            <Grid item>
                              <WorkspacePremium
                                style={{
                                  color: "#eb5454",
                                  fontSize: width > 600 ? "x-large" : "large",
                                }}
                              />
                              {row.points ? row.points : "0"}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
}
