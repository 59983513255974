import {
  Container,
  Grid,
  IconButton,
  Modal,
  ModalUnstyled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  Button,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useViewport } from "../viewport";
import { SectionHeader } from "./SectionHeader";
import "../App.css";
import { useState } from "react";
import METROFLEX from "../assets/NFT_Promp1.png";
import Moralis from "moralis";
import {
  Check,
  Close,
  Collections,
  Leaderboard,
  LocalActivity,
  Menu,
  WorkspacePremium,
} from "@mui/icons-material";
import DefaultAvatar from "../assets/defaultAvatar.png";
import DefaultAvatarF from "../assets/defaultAvatarF.png";
import styled from "@emotion/styled";
import Barcode from "react-barcode";
import { useNavigate } from "react-router-dom";
const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  bgcolor: "black",
  p: 0,
  px: 0,
  pb: 8,
  pt: 8,
  color: "white",
  border: "2px solid #000",
  width: "100%",
};

export function BarcodeScan({ modal, handleClose, selectedUser }) {
  const { width } = useViewport();
  const [input, setInput] = useState();
  const [changeID, setChangeID] = useState(false);
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  selectedUser = JSON.parse(JSON.stringify(selectedUser));

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = input;
    console.log(result);
    try {
      const userObject = await Moralis.User.currentAsync();
      userObject.set("membershipId", result);
      await userObject.save();
      await userObject.fetch();
      handleClose();
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={modal}
      onClose={handleClose}
      onBackdropClick={handleClose}
      BackdropComponent={Backdrop}
    >
      <Box sx={style} style={{ overflow: width < 600 ? "scroll" : "" }}>
        <Grid container direction="column" alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <IconButton onClick={handleClose}>
              <Close style={{ color: "black" }} />
            </IconButton>
            <img
              src={
                selectedUser?.image !== undefined &&
                selectedUser?.image !== "default"
                  ? selectedUser.image
                  : DefaultAvatar
              }
              width="200pt"
              height="200pt"
              style={{ borderRadius: "50%", objectFit: "cover" }}
            ></img>
            <IconButton onClick={handleClose}>
              <Close style={{ color: "white" }} />
            </IconButton>
          </Grid>

          <Typography
            style={{
              marginTop: "20pt",
              marginBottom: "10pt",
              fontSize: "x-large",
              color: "#eb5454",
              fontFamily: "Requiem, sans-serif",
            }}
            align="left"
          >
            {selectedUser?.customUsername}
          </Typography>
          {changeID === false && selectedUser?.membershipId ? (
            <Grid container direction="column">
              <Barcode
                value={selectedUser?.membershipId}
                width={3.5}
                height={150}
                margin={20}
                
              />
              <Button onClick={() => setChangeID(true)}>
                <Typography style={{ fontSize: "small", color: "#929292" }}>
                  Change ID? Click here.
                </Typography>
              </Button>
              <Typography
                style={{
                  color: "white",
                  fontFamily: "Roboto, sans-serif",
                  marginTop: "20pt",
                  marginBottom: "10pt",
                }}
                align="center"
              >
                What are you hitting today?
              </Typography>
              <Button
                onClick={() => {
                  routeChange("/hitlist");
                }}
                variant="outlined"
                style={{
                  borderColor: "#eb5454",
                  textTransform: "none",
                  color: "#eb5454",
                  fontWeight: "bold",
                }}
              >
                Click here to start your list!
              </Button>
            </Grid>
          ) : (
            <Grid container direction="row" justifyContent="center">
              <Box
                border={1}
                style={{
                  borderRadius: "5pt",
                  padding: "5pt",
                  margin: 0,
                }}
              >
                <Input
                  type="text"
                  maxRows={1}
                  style={{ color: "white" }}
                  onSubmit={handleSubmit}
                  value={input}
                  placeholder="Enter Membership ID"
                  onChange={(event) => {
                    event.preventDefault();
                    if (event.target.value.length < 13) {
                      setInput(event.target.value);
                    }
                  }}
                />
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={handleSubmit}
                  style={{
                    borderColor: "transparent",
                    borderRadius: "5pt",
                    textTransform: "none",
                    color: "white",
                    fontFamily: "Montserrat, sans-serf",
                  }}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </StyledModal>
  );
}
