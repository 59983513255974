import { Add, Menu, Remove } from "@mui/icons-material";
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Moralis from "moralis";
import { useState } from "react";
import { useViewport } from "../viewport";
import { NFTView } from "./NFTView";
import "./styles.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#222",
  color: "white",
  width: "80%",
  boxShadow: 24,
  p: 4,
};

export function RaffleView({
  user,
  ethAddress,
  membershipId,
  customUsername,
  toggleDrawer,
}) {
  const [totalPurchase, setTotalPurchase] = useState(1);
  const { width } = useViewport();
  const breakpoint = 620;
  const [pointHistoryLoaded, setPointHistoryLoaded] = useState(false);
  const [points, setPoint] = useState();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [ticketsAvailable, setTicketsAvailable] = useState(false);
  const [raffleTickets, setRaffleTickets] = useState();
  const handleOpen = (i) => {
    setSelected(i);
    setOpen(true);
  };
  const handleClose = () => {
    setSelected();
    setOpen(false);
  };
  const getUserData = async () => {
    setPointHistoryLoaded(true);
    try {
      const params = {
        ethAddress: ethAddress,
      };
      const userStats = await Moralis.Cloud.run("getUserStats", params);
      console.log(userStats);
      setPoint(userStats);
      getData();
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async () => {
    try {
      const params = {
        ethAddress: ethAddress,
      };
      const userStats = new Moralis.Query("RaffleTickets");
      userStats.equalTo("memberId", ethAddress);
      const results = await userStats.find();
      console.log(JSON.parse(JSON.stringify(results)));
      setRaffleTickets(JSON.parse(JSON.stringify(results)));
    } catch (err) {
      console.log(err);
    }
  };
  const claimTicket = async () => {
    try {
      const params = {
        raffleId: 0,
        amount: totalPurchase,
      };
      const userStats = await Moralis.Cloud.run("claimRaffleTicket", params);
      console.log(userStats);
      getUserData();
    } catch (err) {
      console.log(err);
    }
  };

  if (!pointHistoryLoaded) getUserData();
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      justifyItems="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        height: "100%",
        paddingTop: "10pt",
        paddingBottom: "40pt",
        width: "500pt",
        maxWidth: "100vw",
      }}
    >
      <Grid item width="100%" style={{ maxWidth: "" }}>
        <Grid container direction="row" justifyItems="flex-start">
          <IconButton onClick={toggleDrawer(true)}>
            <Menu style={{ color: "white" }} />
          </IconButton>
        </Grid>
      </Grid>
      <Typography
        style={{
          marginTop: "10pt",
          fontFamily: "Road_Rage",
          fontSize: "2em",
          marginBottom: "10pt",
          color: "#eb5454",
        }}
      >
        {"Raffle"}
      </Typography>
      <Typography
        style={{
          color: "white",
          fontFamily: "Requiem, sans-serif",
          marginBottom: "10pt",
          maxWidth: "400pt",
          fontSize: width > breakpoint ? "large" : "1.3em",
        }}
      >
        Tickets for our next drawing available SOON
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container direciton="column" justifyContent="center">
            <Typography variant="h6" component="h2">
              Redeem {totalPurchase} Ticket{totalPurchase === 1 ? "" : "s"}?
            </Typography>
            <Typography
              style={{ fontSize: width < breakpoint ? "0.8em" : "small" }}
            >
              Your Balance: {points?.points} pts.
            </Typography>
            <Typography sx={{ mt: 2, mb: 2, fontSize: "0.9em" }}>
              <b>{totalPurchase === 1 ? "20" : totalPurchase * 20} points</b>{" "}
              will be deducted from your account.
            </Typography>
            <Grid container direction="row" justifyContent="space-around">
              <Button
                variant="contained"
                style={{ background: "grey", textTransform: "none" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              {points?.points >= totalPurchase * 20 ? (
                <Button
                  variant="contained"
                  style={{ background: "#eb5454", textTransform: "none" }}
                  onClick={() => {
                    claimTicket();
                    handleClose();
                  }}
                >
                  Confirm
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    background: "#eb5454",
                    color: "white",
                    textTransform: "none",
                  }}
                  disabled
                >
                  Not enough points
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {ticketsAvailable ? (
        <Box
          border={1}
          style={{
            borderRadius: "10pt",
            overflow: "clip",
            width: "350pt",
            maxWidth: "90%",
            paddingTop: "10pt",
            paddingBottom: "10pt",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Grid item style={{ width: "100%" }}>
              <Grid container direction="row" justifyContent="space-around">
                <Box
                  style={{
                    borderRadius: "10pt",
                    backgroundColor: "grey",
                    width: "50pt",
                    height: "50pt",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    style={{ height: "100%" }}
                    justifyContent="center"
                  >
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "1.8em" }}
                    >
                      ?
                    </Typography>
                  </Grid>
                </Box>
                <Grid item style={{ width: "70%" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: width > breakpoint ? "x-large" : "1.3em",
                      }}
                    >
                      Mystery Prize
                    </Typography>
                    <Typography style={{ fontWeight: "bold" }}>
                      (100pts)
                    </Typography>
                  </Grid>
                  <Typography style={{ fontSize: "medium" }} align="left">
                    Drawing: <b>Apr. 30</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <IconButton
                  onClick={() => {
                    if (totalPurchase > 1) setTotalPurchase(totalPurchase - 1);
                  }}
                >
                  <Remove
                    style={{ color: totalPurchase === 1 ? "grey" : "white" }}
                  />
                </IconButton>
                {totalPurchase}
                <IconButton
                  onClick={() => {
                    if (totalPurchase * 20 < points?.points)
                      setTotalPurchase(totalPurchase + 1);
                  }}
                >
                  <Add
                    style={{
                      color:
                        totalPurchase * 20 < points?.points ? "white" : "grey",
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
            {points &&
              (points.points >= 20 * totalPurchase ? (
                <Button
                  variant="contained"
                  onClick={() => handleOpen(1)}
                  style={{ background: "#eb5454", textTransform: "none" }}
                >
                  Buy {totalPurchase} ticket{totalPurchase === 1 ? "" : "s"}
                </Button>
              ) : (
                <Typography style={{ color: "grey" }}>
                  Not enough points
                </Typography>
              ))}
          </Grid>
        </Box>
      ) : (
        <Typography style={{fontSize: "x-large"}}>🤫</Typography>
      )}
      {raffleTickets && (
        <Grid container direction="column">
          <Typography
            style={{
              marginTop: "20pt",
              marginBottom: "10pt",
              fontSize: width > breakpoint ? "x-large" : " 1.3em",
              fontWeight: "bold",
            }}
          >
            {" "}
            My Tickets
          </Typography>
          <Grid item style={{ width: "100%" }}>
            <Grid container direction="column" alignItems="center">
              {raffleTickets.map((ticket) => (
                <div
                  style={{
                    width: "100%",
                    maxWidth: "400pt",
                  }}
                >
                  <div
                    key={ticket.objectId}
                    class="ticket"
                    style={{
                      overflow: "clip",
                      marginLeft: "25pt",
                      marginRight: "25pt",
                      marginTop: "10pt",
                      marginBottom: "10pt",
                    }}
                  >
                    <div class="ticket-content-wrapper">
                      <Typography>Raffle Ticket</Typography>
                      <Typography
                        style={{ fontWeight: "bold", color: "#e7d155" }}
                      >
                        #{ticket.ticketId}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: width < breakpoint ? "0.8em" : "small",
                        }}
                      >
                        Bought on:
                      </Typography>
                      <Typography
                        style={{
                          fontSize: width < breakpoint ? "0.8em" : "small",
                        }}
                      >
                        {new Date(ticket.createdAt).toLocaleDateString()}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
