import { Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import Moralis from "moralis";
import React, { useState } from "react";
import ReactPageScroller from "react-page-scroller";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Background from "./assets/NFT_Promp.png";
import { AdminView } from "./components/AdminView";
import { LeaderboardView } from "./components/Leaderboard";
import { MainBody } from "./components/MainBody";
import { MainHeader } from "./components/MainHeader";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Menu } from "@mui/icons-material";
import Logo from "./assets/logo.png";
import { SideMenu } from "./components/SideMenu";
import { MyAccount } from "./components/MyAccount";
import { MyHitList } from "./components/MyHitList";
import { AboutView } from "./components/AboutView";
import { FAQView } from "./components/FAQ";
import { ErrorView } from "./components/Error";
import { RaffleView } from "./components/RaffleView";
import { MyProfile } from "./components/MyProfile";
import { ForumView } from "./components/ForumView";
import { ForumDetailView } from "./components/ForumDetailView";
import AddToHomeScreen from "@ideasio/add-to-homescreen-react";

const Root = styled("div")(({ theme }) => ({
  height: "100%",
}));

function App(props) {
  const [user, setUser] = useState();
  const [network, setNetwork] = useState();
  const [bonusCount, setBonusCount] = useState(0);
  const [nftCount, setNFTCount] = useState(0);
  const [admin, setAdmin] = useState();
  const [points, setPoints] = useState();
  const [membershipId, setMembershipId] = useState();
  const [customUsername, setCustomUsername] = useState();
  const [userLoaded, setUserLoaded] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState();
  const [chainChanged, setUnchain] = useState();
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  async function login() {
    console.log("LOGIN FUNCTION TRIGGERED");
    setUserLoaded(true);
    // const network = await Moralis.chainId;
    // const accounts = await Moralis.account;
    setAdmin();
    setUser();
    setCustomUsername();
    try {
      let user;
      try {
        user = await Moralis.User.currentAsync();
      } catch (err) {
        console.log(err);
      }
      if (user === null || user === undefined || !user)
        user = Moralis.User.current();
      try {
        await user?.fetch();
      } catch (err) {
        console.log(err);
      }
      console.log(user);
      let userAccount;
      try {
        const params = {
          memberId: user?.id,
        };
        const userStats = await Moralis.Cloud.run("getUserStats", params);
        console.log(userStats);
        setPoints(userStats.points);
      } catch (err) {
        console.log(err);
        setPoints(0);
      }
      // try {
      //   const network = Moralis.chainId;
      //   console.log(network);
      //   setNetwork(network);
      // } catch (err) {
      //   const network = Moralis.chainId;
      //   setNetwork(network);
      //   console.log(err);
      // }
      try {
        userAccount = JSON.parse(JSON.stringify(user.get("ethAddress")));
      } catch (err) {
        console.log(err);
      }
      if (!user /*&& userAccount !== accounts*/) {
        user = await Moralis.authenticate({
          signingMessage: "Show MetroFlex your Wallet Address?",
        })
          .then(function (user) {
            console.log("logged in user:", user);
            console.log(user.get("ethAddress"));
            setAdmin(user);
            setUser(userAccount);
            setCustomUsername(
              JSON.parse(JSON.stringify(user.get("customUsername")))
            );
            var count = 0;
            if (user?.get("welcomeAcknowledged") === undefined) count += 1;
            if (user?.get("PRBonus") === undefined) count += 1;
            if (user?.get("PicBonus") === undefined) count += 1;
            if (user?.get("BioBonus") === undefined) count += 1;
            setBonusCount(count);
            count = 0;
            console.log(user?.murals);
            if (user.get("murals") === undefined) count = 1;
            setNFTCount(count);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log(JSON.parse(JSON.stringify(user)));
        setAdmin(user);
        setUser(userAccount);
        setCustomUsername(
          JSON.parse(
            JSON.stringify(
              user.get("customUsername")
                ? user.get("customUsername")
                : user.get("username")
                ? user.get("username")
                : null
            )
          )
        );
        var count = 0;
        if (user?.get("welcomeAcknowledged") === undefined) count += 1;
        if (user?.get("PRBonus") === undefined) count += 1;
        if (user?.get("PicBonus") === undefined) count += 1;
        if (user?.get("BioBonus") === undefined) count += 1;
        setBonusCount(count);
        count = 0;
        console.log(user?.murals);
        if (user.get("murals") === undefined) count = 1;
        setNFTCount(count);
      }
      var unsub = Moralis.onAccountChanged(async (account) => {
        // TODO: ADD USER CONFIRMATION LOGIC HERE
        try {
          console.log("TRIGGERED ACCOUNTS CHANGED!!");
          console.log(account);
          setUser(account);
        } catch (error) {
          const code = error.code;
          const message = error.message;
        }
      });
      var unchain = Moralis.onChainChanged(async (chain) => {
        console.log("CHAIN CHANGED!!!");
        console.log(chain);
        this.login();
      });
      setUnsubscribe(unsub);
      setUnchain(unchain);
    } catch (err) {
      console.log(err);
    }
  }

  if (!userLoaded) login();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div
      className="App"
      style={{
        background: "url(" + Background + ")",
        backgroundPosition: "top",
        backgroundSize: "auto",
      }}
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
        maxWidth="800pt"
        style={{ maxWidth: "800pt" }}
      >
        <AddToHomeScreen startAutomatically={true} />
        <Router>
          <Routes>
            <Route
              path="/admin"
              element={<AdminView user={admin} network={network} />}
            />
            <Route
              path="/leaderboard"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />
                  {admin ? (
                    <LeaderboardView
                      isHomePage={false}
                      toggleDrawer={toggleDrawer}
                      count={bonusCount}
                      nftCount={nftCount}
                    />
                  ) : (
                    <ErrorView
                      toggleDrawer={toggleDrawer}
                      error={"leaderboard"}
                      user={admin}
                      customUsername={customUsername}
                      ethAddress={user}
                      login={login}
                    />
                  )}
                </Root>
              }
            />
            <Route
              path="/journey"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />

                  {admin ? (
                    <MyAccount
                      user={admin}
                      ethAddress={user}
                      customUsername={customUsername}
                      toggleDrawer={toggleDrawer}
                      login={login}
                      count={bonusCount}
                      nftCount={nftCount}
                    />
                  ) : (
                    <ErrorView
                      toggleDrawer={toggleDrawer}
                      error={"my mural"}
                      user={admin}
                      customUsername={customUsername}
                      ethAddress={user}
                      login={login}
                    />
                  )}
                </Root>
              }
            />{" "}
            <Route
              path="/forum/:forumName"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />
                  {admin ? (
                    <ForumDetailView
                      user={admin}
                      ethAddress={user}
                      customUsername={customUsername}
                      toggleDrawer={toggleDrawer}
                      count={bonusCount}
                      nftCount={nftCount}
                    />
                  ) : (
                    <ErrorView
                      toggleDrawer={toggleDrawer}
                      error={"forum"}
                      user={admin}
                      customUsername={customUsername}
                      ethAddress={user}
                      login={login}
                    />
                  )}
                </Root>
              }
            />
            <Route
              path="/forum"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />
                  {admin ? (
                    <ForumView
                      user={admin}
                      ethAddress={user}
                      customUsername={customUsername}
                      toggleDrawer={toggleDrawer}
                      count={bonusCount}
                      nftCount={nftCount}
                    />
                  ) : (
                    <ErrorView
                      toggleDrawer={toggleDrawer}
                      error={"forum"}
                      user={admin}
                      customUsername={customUsername}
                      ethAddress={user}
                      login={login}
                    />
                  )}
                </Root>
              }
            />
            <Route
              path="/raffle"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />

                  {admin ? (
                    <RaffleView
                      user={admin}
                      ethAddress={user}
                      customUsername={customUsername}
                      toggleDrawer={toggleDrawer}
                      nftCount={nftCount}
                    />
                  ) : (
                    <ErrorView
                      toggleDrawer={toggleDrawer}
                      error={"raffle"}
                      user={admin}
                      customUsername={customUsername}
                      ethAddress={user}
                      login={login}
                    />
                  )}
                </Root>
              }
            />
            <Route
              path="/faq"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />
                  <FAQView />
                </Root>
              }
            />
            <Route
              path="/hitlist"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />
                  {admin ? (
                    <MyHitList
                      toggleDrawer={toggleDrawer}
                      user={admin}
                      count={bonusCount}
                      nftCount={nftCount}
                    />
                  ) : (
                    <ErrorView
                      toggleDrawer={toggleDrawer}
                      error={"hit list"}
                      user={admin}
                      customUsername={customUsername}
                      ethAddress={user}
                      login={login}
                    />
                  )}
                </Root>
              }
            />
            <Route
              path="/myprofile"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />

                  {admin ? (
                    <MyProfile
                      user={admin}
                      ethAddress={user}
                      customUsername={customUsername}
                      toggleDrawer={toggleDrawer}
                      login={login}
                      nftCount={nftCount}
                    />
                  ) : (
                    <ErrorView
                      toggleDrawer={toggleDrawer}
                      error={"my profile"}
                      user={admin}
                      customUsername={customUsername}
                      ethAddress={user}
                      login={login}
                    />
                  )}
                </Root>
              }
            />
            <Route
              path="/"
              element={
                <Root>
                  <SideMenu
                    toggleDrawer={toggleDrawer}
                    open={open}
                    container={container}
                    user={admin}
                    count={bonusCount}
                    nftCount={nftCount}
                  />
                  <MainHeader
                    user={admin}
                    ethAddress={user}
                    points={points}
                    membershipId={membershipId}
                    customUsername={customUsername}
                    toggleDrawer={toggleDrawer}
                    login={login}
                    count={bonusCount}
                    nftCount={nftCount}
                  />
                  <AboutView />
                </Root>
              }
            />
          </Routes>
        </Router>
      </Grid>
    </div>
  );
}

export default App;
