import {
  Add,
  AddAPhoto,
  ArrowRight,
  ArrowUpward,
  ChatBubbleOutlineOutlined,
  Close,
  Error,
  Favorite,
  FavoriteBorder,
  KeyboardReturn,
  MoreVert,
  ThumbUpAlt,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import {
  Container,
  IconButton,
  Typography,
  Grid,
  TextField,
  OutlinedInput,
  FormControl,
  InputAdornment,
  styled,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useState } from "react";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en-short";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import Moralis from "moralis";
import { useFilePicker } from "use-file-picker";
import DefaultAvatar from "../assets/defaultAvatar.png";
import ReactPlayer from "react-player";
import { useViewport } from "../viewport";
const formatter = buildFormatter(englishStrings);
const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: "#222",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const CustomFSDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: "#222",
    margin: 0,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

const CssTextField = styled(OutlinedInput)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    background: "grey",
    borderColor: "#eb5454",
    borderTopLeftRadius: "5pt",
    borderBottomLeftRadius: "5pt",
    "& fieldset": {
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
    "&:hover fieldset": {
      borderColor: "#eb5454",
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#eb5454",
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
  },
});
export function ForumDetailTile({
  post,
  currentUser,
  handleOpen,
  getForumFeed,
  forumName,
}) {
  const [showComment, openComment] = useState(false);
  const { width } = useViewport();
  const [open, openDialog] = useState(false);
  const [fullScreenImage, showFullscreenImage] = useState(false);
  const [selectedMedia, selectMedia] = useState(false);
  const [selectedPost, selectPost] = useState();
  const [newPost, setNewPost] = useState("");
  const [openFileSelector, { filesContent, errors, loading, clear }] =
    useFilePicker({
      readAs: "DataURL",
      accept: ["image/*", "video/*"],

      multiple: false,
      limitFilesConfig: { max: 1 },
      maxFileSize: 50,
    });
  const likePostById = async (objectId) => {
    console.log("liking: " + objectId);
    try {
      const params = { objectId: objectId };
      const res = await Moralis.Cloud.run("likePostById", params);
      getForumFeed();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const thumbsupPostById = async (objectId) => {
    console.log("liking: " + objectId);
    try {
      const params = { objectId: objectId };
      const res = await Moralis.Cloud.run("thumbsupPostById", params);
      getForumFeed();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const removePost = async (objectId) => {
    console.log("deleting: " + objectId);
    try {
      const params = { postId: objectId };
      const res = await Moralis.Cloud.run("removePost", params);
      getForumFeed();
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    const u = await Moralis.User.currentAsync();
    if (filesContent[0]?.content !== undefined) {
      const moralisFile = new Moralis.File("forumPost", {
        uri: filesContent[0].content,
      });
      moralisFile.save().then(
        async function (value) {
          console.log(value);
          const params = {
            media: value._url,
            topic: forumName,
            text: newPost,
            postId: post.id,
          };
          const res = await Moralis.Cloud.run("addComment", params);
          clear();
          setNewPost("");
          openComment(false);
          getForumFeed();
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      const params = {
        media: undefined,
        topic: forumName,
        text: newPost,
        postId: post.id,
      };
      const res = await Moralis.Cloud.run("addComment", params);
      setNewPost("");
      openComment(false);
      getForumFeed();
    }
  };
  console.log(currentUser);
  return (
    <Grid
      container
      direction="column"
      justifyContent={"flex-start"}
      style={{
        marginTop: "10pt",
        marginBottom: "5pt",
        maxWidth: "500pt",
        margin: "auto",
      }}
    >
      <CustomFSDialog
        onClose={() => {
          showFullscreenImage(false);
          selectMedia(null);
        }}
        open={fullScreenImage}
      >
        <DialogContent style={{ color: "white" }}>
          <DialogContentText style={{ color: "white" }}>
            {selectedMedia?.media !== "" &&
            ReactPlayer.canPlay(selectedMedia?.media) ? (
              <ReactPlayer
                url={selectedMedia.media}
                controls={true}
                muted={true}
                playing={true}
                //width={width < 400 ? width * 0.75 : 640}
                width="100%"
                style={{
                  objectFit: "contain",
                  maxHeight: "500pt",
                  overflow: "hidden",
                }}
              />
            ) : (
              <img
                src={selectedMedia?.media}
                width="100%"
                style={{
                  objectFit: "contain",
                  maxHeight: "500pt",
                  overflow: "hidden",
                }}
              />
            )}
          </DialogContentText>
        </DialogContent>
      </CustomFSDialog>
      <CustomDialog onClose={() => openDialog(false)} open={open}>
        <DialogTitle style={{ color: "white" }}>Delete Post?</DialogTitle>
        <DialogContent style={{ color: "white" }}>
          <DialogContentText style={{ color: "white" }}>
            {selectedPost?.text}
            {selectedPost?.media !== "" && (
              <Grid
                item
                style={{
                  borderRadius: "10pt",
                  overflow: "hidden",
                }}
              >
                {selectedMedia?.media !== "" &&
                ReactPlayer.canPlay(selectedMedia?.media) ? (
                  <ReactPlayer
                    url={selectedMedia.media}
                    controls={true}
                    muted={true}
                    playing={true}
                    width="100%"
                    style={{
                      objectFit: "contain",
                      maxHeight: "500pt",
                      overflow: "hidden",
                    }}
                  />
                ) : (
                  <img
                    src={selectedMedia?.media}
                    width="100%"
                    style={{
                      objectFit: "contain",
                      maxHeight: "500pt",
                      overflow: "hidden",
                    }}
                  />
                )}
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ color: "white", justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{ background: "grey", color: "white", width: "45%" }}
            onClick={() => {
              selectPost();
              openDialog(false);
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            style={{ background: "#eb5454", color: "white", width: "45%" }}
            onClick={() => {
              removePost(post?.id);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </CustomDialog>
      <Grid item>
        <Grid container direction="row" wrap="nowrap">
          <Grid item>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                handleOpen(JSON.parse(JSON.stringify(post?.author)));
              }}
            >
              <img
                src={
                  post?.author !== undefined
                    ? JSON.parse(JSON.stringify(post?.author))?.image ===
                      "default"
                      ? DefaultAvatar
                      : JSON.parse(JSON.stringify(post?.author))?.image
                    : null
                }
                style={{
                  width: "40pt",
                  height: "40pt",
                  borderRadius: "40pt",
                  objectFit: "cover",
                }}
              />
            </IconButton>
          </Grid>
          <Grid
            item
            style={{
              paddingLeft: "10pt",
              paddingRight: "10pt",
              width: "100%",
            }}
          >
            <Grid container direction="column">
              <Grid
                container
                direction="row"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <Grid container direction="row">
                    <Typography style={{ fontWeight: "bold" }} align="left">
                      {post?.author &&
                        JSON.parse(JSON.stringify(post?.author)).customUsername}
                    </Typography>

                    <Typography
                      style={{
                        fontWeight: "normal",
                        color: "grey",
                        marginLeft: "6pt",
                      }}
                      align="left"
                    >
                      ·
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "normal",
                        color: "grey",
                        marginLeft: "6pt",
                      }}
                      align="left"
                    >
                      <TimeAgo date={post?.createdAt} formatter={formatter} />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  {((currentUser?.id || currentUser?.objectId) ===
                    "BcxIGp8FarTgazB7P4FvwEJ2" ||
                    (currentUser?.id || currentUser?.objectId) ===
                      "XhfOK2iA0coZGFlBwHRHGGAe" ||
                    (currentUser?.id || currentUser?.objectId) ===
                      post?.author?.id) && (
                    <IconButton
                      onClick={(event) => {
                        event.preventDefault();
                        console.log(post.objectId);
                        selectPost(post);
                        openDialog(true);
                      }}
                    >
                      <Close style={{ color: "white" }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Typography align="left">{post?.text}</Typography>
              {post?.media !== "" && (
                <Grid
                  item
                  style={{
                    borderRadius: "10pt",
                    overflow: "hidden",
                  }}
                >
                  {post?.media !== "" && ReactPlayer.canPlay(post?.media) ? (
                    <ReactPlayer
                      url={post.media}
                      controls={true}
                      muted={true}
                      playing={true}
                      width="100%"
                      style={{
                        objectFit: "contain",
                        maxHeight: "500pt",
                        overflow: "hidden",
                      }}
                    />
                  ) : (
                    <img
                      src={post?.media}
                      onClick={() => {
                        selectMedia(post);
                        showFullscreenImage(true);
                      }}
                      width="100%"
                      style={{
                        objectFit: "contain",
                        maxHeight: "500pt",
                        overflow: "hidden",
                      }}
                    />
                  )}
                </Grid>
              )}
              <Grid
                item
                style={{
                  paddingLeft: "20pt",
                  borderRadius: "10pt",
                  paddingRight: "20pt",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent={"space-between"}
                >
                  <IconButton onClick={(event) => openComment(!showComment)}>
                    <ChatBubbleOutlineOutlined
                      style={{
                        color: "white",
                        marginRight: "10pt",
                        fontSize: "medium",
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: "medium",
                        fontFamily: "Roboto, sans-serif",
                        color: "white",
                      }}
                    >
                      {post?.comments ? post?.comments?.length : "0"}
                    </Typography>
                  </IconButton>
                  <IconButton onClick={(event) => likePostById(post?.id)}>
                    {post?.likes?.includes(currentUser?.id) ? (
                      <Favorite
                        style={{
                          color: "#eb5454",
                          marginRight: "10pt",
                          fontSize: "medium",
                        }}
                      />
                    ) : (
                      <FavoriteBorder
                        style={{
                          color: "white",
                          marginRight: "10pt",
                          fontSize: "medium",
                        }}
                      />
                    )}
                    <Typography
                      style={{
                        fontSize: "medium",
                        fontFamily: "Roboto, sans-serif",
                        color: "white",
                      }}
                    >
                      {post?.likes?.length}
                    </Typography>
                  </IconButton>
                  <IconButton onClick={(event) => thumbsupPostById(post?.id)}>
                    {post?.thumbsup?.includes(currentUser.objectId) ? (
                      <ThumbUpAlt
                        style={{
                          color: "#eb5454",
                          marginRight: "10pt",
                          fontSize: "medium",
                        }}
                      />
                    ) : (
                      <ThumbUpAltOutlined
                        style={{
                          color: "white",
                          marginRight: "10pt",
                          fontSize: "medium",
                        }}
                      />
                    )}

                    <Typography
                      style={{
                        fontSize: "medium",
                        fontFamily: "Roboto, sans-serif",
                        color: "white",
                      }}
                    >
                      {post?.thumbsup ? post?.thumbsup?.length : "0"}
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showComment && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            wrap="nowrap"
            style={{ paddingLeft: "40pt" }}
          >
            <FormControl
              sx={{ m: 0, color: "white", borderColor: "#eb5454" }}
              variant="outlined"
            >
              {/* <InputLabel style={{ color: "white" }} >New Post</InputLabel> */}
              <CssTextField
                value={newPost}
                onChange={(event) => {
                  event.preventDefault();
                  setNewPost(event.target.value);
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  setNewPost(event.target.value + "\n");
                  console.log(newPost);
                }}
                multiline
                style={{ background: "grey" }}
                autoComplete="off"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={
                          currentUser?.image === "default"
                            ? DefaultAvatar
                            : currentUser?.image
                            ? currentUser.image
                            : null
                        }
                        height="30pt"
                        width="30pt"
                        style={{ borderRadius: "30pt" }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      onClick={(event) => {
                        openFileSelector();
                      }}
                    >
                      <AddAPhoto />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Add Comment"
              />
              {filesContent[0]?.content && (
                <Container style={{ background: "grey" }}>
                  <IconButton
                    onClick={clear}
                    style={{
                      position: "absolute",
                      right: 4,
                      background: "black",
                    }}
                  >
                    <Close style={{ color: "white" }} />
                  </IconButton>
                  <img
                    src={filesContent[0]?.content}
                    width="100%"
                    style={{
                      maxHeight: "200pt",
                      borderRadius: "10pt",
                      objectFit: "cover",
                      marginTop: "5pt",
                    }}
                  />
                </Container>
              )}
              {errors.length > 0 && (
                <Container style={{ background: "grey" }}>
                  <Error /> {errors[0].name}
                </Container>
              )}
            </FormControl>
            <IconButton
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              style={{
                background: "#eb5454",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                textTransform: "none",
              }}
            >
              <Add />
            </IconButton>
          </Grid>
        )}
        {post?.comments?.length > 0 &&
          post?.comments?.map((comment) => {
            comment = JSON.parse(JSON.stringify(comment));
            return (
              <Grid
                container
                direction="column"
                style={{ maxWidth: "80%", marginLeft: "auto" }}
                alignItems="flex-end"
              >
                {" "}
                <Container
                  style={{
                    background: "#eb5454",
                    borderRadius: "5pt",
                    height: "1pt",
                  }}
                />
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  style={{
                    border: "1pt",
                    borderColor: "white",
                    background: "rgb(28,28,28)",
                  }}
                >
                  <Grid item>
                    <IconButton
                      onClick={(event) => {
                        event.preventDefault();
                        handleOpen(comment.author);
                      }}
                    >
                      <img
                        src={
                          comment.author?.image == "default"
                            ? DefaultAvatar
                            : comment.author?.image
                        }
                        style={{
                          width: "40pt",
                          height: "40pt",
                          borderRadius: "40pt",
                          objectFit: "cover",
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    style={{
                      paddingLeft: "10pt",
                      paddingRight: "10pt",
                      width: "100%",
                    }}
                  >
                    <Grid container direction="column">
                      <Grid container direction="row">
                        <Typography style={{ fontWeight: "bold" }} align="left">
                          {comment.author?.customUsername
                            ? comment.author?.customUsername
                            : comment.author?.username}
                        </Typography>

                        <Typography
                          style={{
                            fontWeight: "normal",
                            color: "grey",
                            marginLeft: "6pt",
                          }}
                          align="left"
                        >
                          ·
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "normal",
                            color: "grey",
                            marginLeft: "6pt",
                          }}
                          align="left"
                        >
                          <TimeAgo
                            date={comment.createdAt}
                            formatter={formatter}
                          />
                        </Typography>
                      </Grid>
                      <Typography align="left">{comment.text}</Typography>
                      {comment.media !== "" && (
                        <Grid
                          item
                          style={{
                            borderRadius: "10pt",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={comment.media}
                            width="100%"
                            style={{
                              objectFit: "contain",
                              maxHeight: "500pt",
                              overflow: "hidden",
                            }}
                          />
                        </Grid>
                      )}
                      {/* <Grid
                      item
                      style={{
                        paddingLeft: "20pt",
                        borderRadius: "10pt",
                        paddingRight: "20pt",
                        width: "100%",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent={"space-between"}
                      >
                        <IconButton
                          onClick={(event) => openComment(!showComment)}
                        >
                          <ChatBubbleOutlineOutlined
                            style={{
                              color: "white",
                              marginRight: "10pt",
                              fontSize: "medium",
                            }}
                          />
                          <Typography
                            style={{
                              fontSize: "medium",
                              fontFamily: "Roboto, sans-serif",
                              color: "white",
                            }}
                          >
                            {comment.comments ? comment.comments?.length : "0"}
                          </Typography>
                        </IconButton>
                        <IconButton
                          onClick={(event) => likePostById(comment.id)}
                        >
                          {comment.likes?.includes(currentUser.objectId) ? (
                            <Favorite
                              style={{
                                color: "#eb5454",
                                marginRight: "10pt",
                                fontSize: "medium",
                              }}
                            />
                          ) : (
                            <FavoriteBorder
                              style={{
                                color: "white",
                                marginRight: "10pt",
                                fontSize: "medium",
                              }}
                            />
                          )}
                          <Typography
                            style={{
                              fontSize: "medium",
                              fontFamily: "Roboto, sans-serif",
                              color: "white",
                            }}
                          >
                            {comment.likes?.length}
                          </Typography>
                        </IconButton>
                        <IconButton
                          onClick={(event) => thumbsupPostById(comment.id)}
                        >
                          {comment.thumbsup?.includes(currentUser.objectId) ? (
                            <ThumbUpAlt
                              style={{
                                color: "#eb5454",
                                marginRight: "10pt",
                                fontSize: "medium",
                              }}
                            />
                          ) : (
                            <ThumbUpAltOutlined
                              style={{
                                color: "white",
                                marginRight: "10pt",
                                fontSize: "medium",
                              }}
                            />
                          )}

                          <Typography
                            style={{
                              fontSize: "medium",
                              fontFamily: "Roboto, sans-serif",
                              color: "white",
                            }}
                          >
                            {comment.thumbsup ? comment.thumbsup?.length : "0"}
                          </Typography>
                        </IconButton>
                      </Grid>
                    </Grid> */}
                    </Grid>
                  </Grid>{" "}
                  <Grid item>
                    <KeyboardReturn />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        <Container
          style={{
            height: "1pt",
            background: "#eb5454",
            width: "100%",
            marginTop: "5pt",
          }}
        ></Container>
      </Grid>
    </Grid>
  );
}
