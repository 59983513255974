import {
  Cancel,
  CheckBoxSharp,
  CheckCircleOutline,
  Error,
  ExitToApp,
} from "@mui/icons-material";
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box, grid, mergeBreakpointsInOrder } from "@mui/system";
import Moralis from "moralis";
import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import ReactTypingEffect from "react-typing-effect";
import "../App.css";
import Logo from "../assets/logo.png";
import { useViewport } from "../viewport";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MemberManagementView } from "./MemberManagementView";
import { MemberHistoryView } from "./MemberHistoryView";
import { MemberRedemptionView } from "./MemberRedemption";

const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  select: {
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
    "&:hover:not(.Mui-disabled):before": { borderColor: "white" },
  },
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
}));

export function AdminView({ user, membershipId, network }) {
  const { width } = useViewport();
  const [users, setUsers] = useState();
  const [achievementsCompleted, setAchievementsCompleted] = useState([]);
  const [achievementsPending, setAchievementsPending] = useState(new Map());
  const [selectedUser, setSelectedUser] = useState("");
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [pointHistoryLoaded, setPointHistoryLoaded] = useState(false);
  const [pointHistory, setPointHistory] = useState();
  const [points, setPoint] = useState();
  const [mintsPending, setMintsPending] = useState([]);

  const getUserData = async () => {
    setUsersLoaded(true);
    getAchievementPending();
    getMintsPending();
    try {
      fetch(
        "https://df7f3ghoensc.usemoralis.com:2053/server/functions/getLeaderboard?_ApplicationId=6GQoJjklMnsK2skhUTTa2EyGgvXl59gGB2mhzekZ"
      )
        .then((result) => result.json())
        .then((data) => {
          setUsers(data.result);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getAchievementHistory = async () => {
    console.log(selectedUser);
    const AchievementObject = Moralis.Object.extend("AchievementsCompleted");
    const AchievementsQuery = new Moralis.Query(AchievementObject);
    AchievementsQuery.equalTo("memberId", user.ethAddress);
    AchievementsQuery.ascending("dateCompleted");
    const results = await AchievementsQuery.find();
    setAchievementsCompleted(JSON.parse(JSON.stringify(results)));
    console.log(results);
  };

  const getMintsPending = async () => {
    const results = JSON.parse(
      JSON.stringify(await Moralis.Cloud.run("getMintRequests"))
    );
    console.log(results);
    setMintsPending(results);
  };
  const getAchievementPending = async () => {
    console.log(user);
    const AchievementObject = Moralis.Object.extend("AchievementsPending");
    const AchievementsQuery = new Moralis.Query(AchievementObject);
    AchievementsQuery.ascending("dateCompleted");
    const results = JSON.parse(JSON.stringify(await AchievementsQuery.find()));
    console.log(JSON.parse(JSON.stringify(results)));
    var pendingRequestsGrouped = new Map();
    for (var i = 0; i < results.length; i++) {
      console.log("Trying to add: " + results[i]);
      var temp = pendingRequestsGrouped.get(results[i].memberId);
      if (temp === undefined)
        pendingRequestsGrouped.set(results[i].memberId, new Array(results[i]));
      else {
        temp.push(results[i]);
        pendingRequestsGrouped.set(results[i].memberId, temp);
      }
    }
    console.log(pendingRequestsGrouped);
    setAchievementsPending(pendingRequestsGrouped);
  };

  const addAchievementsAdmin = async (achievements, userId) => {
    const params = { achievements: achievements, memberId: userId };
    const remove = await Moralis.Cloud.run("addAchievements", params);
    getUserData();
  };

  const mintNFTAdmin = async (ethAddress) => {
    //     function mintFor(
    //     address to,
    //     uint256 id,
    //     uint256 amount,
    //     uint256 edition,
    //     bytes memory /*data*/
    // )
    console.log(ethAddress);
    const ABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "edition",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        name: "mintFor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ]; // Add ABI of 0xdAC17F958D2ee523a2206206994597C13D831ec7

    const options = {
      contractAddress: "0xfAFC743Ed16f1607A3EE0dB42E535289F2a2C9c1",
      functionName: "mintFor",
      abi: ABI,
      params: { to: ethAddress, id: 0, amount: 1, edition: 5, data: 0x0 },
    };
    await Moralis.enableWeb3();
    const allowance = await Moralis.executeFunction(options); // Wait until the transaction is confirmed
    const message = await allowance.wait();

    // Read new value
    console.log(message);
  };

  const rejectNFTAdmin = async (ethAddress) => {};
  const rejectAchievementsAdmin = async (userId) => {
    const AchievementObject = Moralis.Object.extend("AchievementsPending");
    const AchievementsQuery = new Moralis.Query(AchievementObject);
    AchievementsQuery.equalTo("memberId", userId);
    const results = await AchievementsQuery.find();
    console.log(JSON.parse(JSON.stringify(results)));
    for (var i = 0; i < results.length; i++) {
      results[i].destroy();
      results[i].save();
    }
    getUserData();
  };

  if (!usersLoaded) getUserData();

  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      style={{
        background: "rgba(27,25,24,255)",
        minHeight: "100vh",
        paddingTop: width < 800 ? "30%" : "5%",
        paddingBottom: "10pt",
      }}
    >
      <img src={Logo} width="90%" style={{ maxWidth: "400pt" }} />
      <Grid container direction="row" justifyContent="center">
        {/* <Typography style={{ fontSize: "xxx-large" }}>MetroFlex</Typography> */}
      </Grid>
      <Box
        border={1}
        style={{
          borderRadius: "5pt",
          borderColor: "rgba(255, 255, 255, 0.3)",
          width: "90%",
          marginBottom: "50pt",
        }}
      >
        <Container style={{ marginTop: "10pt", marginBottom: "10pt" }}>
          <Grid
            container
            direction="row"
            alignContent="center"
            justifyContent="center"
          >
            <Typography>Admin Dashboard</Typography>
          </Grid>
          {user?.id !== "BcxIGp8FarTgazB7P4FvwEJ2" &&
            user?.id !== "XhfOK2iA0coZGFlBwHRHGGAe" &&
            user?.id !== "Zk2WGKhhrqCqsTD1AY5VlP8R" && (
              <Grid container direction="row" style={{ marginTop: "10pt" }}>
                <Error style={{ color: "red" }} />
                <Typography style={{ color: "grey" }}>
                  You are NOT Admin {user?.id}
                </Typography>
              </Grid>
            )}
        </Container>
        {(user?.id === "BcxIGp8FarTgazB7P4FvwEJ2" ||
          user?.id === "XhfOK2iA0coZGFlBwHRHGGAe" ||
          user?.id !== "Zk2WGKhhrqCqsTD1AY5VlP8R") && (
          <div>
            {mintsPending.map((userPending) => {
              console.log(userPending);
              return (
                <Grid
                  container
                  key={userPending.username + "grid"}
                  direction="row"
                  justifyContent="center"
                >
                  <Card
                    style={{
                      maxWidth: "400pt",
                      padding: "5pt",
                      margin: "10pt",
                      width: "100%",
                      color: "white",
                    }}
                    className="mintPending"
                  >
                    <Container
                      style={{ background: "#292929", borderRadius: "5pt" }}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          style={{
                            fontSize: "0.9em",
                            fontWeight: "bold",
                            marginRight: "3pt",
                          }}
                        >
                          {userPending.customUsername
                            ? userPending.customUsername
                            : "NoName"}
                        </Typography>
                        <Typography style={{ fontSize: "0.8em" }}>
                          {" said they completed the mural!"}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                      >
                        <Button
                          variant="contained"
                          onClick={(event) => {
                            event.preventDefault();
                            mintNFTAdmin(userPending.ethAddress);
                          }}
                        >
                          Mint NFT
                        </Button>
                        <Button
                          variant="contained"
                          style={{ background: "red" }}
                          onClick={(event) => {
                            event.preventDefault();
                            rejectNFTAdmin(userPending.username);
                          }}
                        >
                          Reject
                        </Button>
                      </Grid>{" "}
                      <Typography
                        style={{
                          fontSize: "0.5em",
                          marginTop: "5pt",
                        }}
                      >
                        {"Crypto Address: " + userPending.ethAddress}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "1em",
                          marginTop: "5pt",
                          color: "red",
                        }}
                      >
                        {" "}
                        Do not use yet until mainnet version
                      </Typography>
                    </Container>
                  </Card>
                </Grid>
              );
            })}
            {achievementsPending.size > 0 &&
              achievementsPending.toJSON().map((userPending) => {
                console.log(userPending);
                return (
                  <Grid
                    container
                    key={userPending[0] + "grid"}
                    direction="row"
                    justifyContent="center"
                  >
                    <Card
                      style={{
                        maxWidth: "400pt",
                        padding: "10pt",
                        margin: "10pt",
                        width: "100%",
                        background: "#292929",
                        color: "white",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          style={{
                            fontSize: "0.9em",
                            fontWeight: "bold",
                            marginRight: "3pt",
                          }}
                        >
                          {userPending[1][0].user_pointer?.customUsername
                            ? userPending[1][0].user_pointer?.customUsername
                            : userPending[1][0].user_pointer?.username
                            ? userPending[1][0].user_pointer?.username
                            : userPending[1][0].username
                            ? userPending[1][0].username
                            : "NoName"}
                        </Typography>
                        <Typography style={{ fontSize: "0.8em" }}>
                          {" said they completed " +
                            userPending[1].length +
                            " hit" +
                            (userPending[1].length === 1 ? "" : "s") +
                            ":"}
                        </Typography>
                      </Grid>
                      {userPending[1].map((achievement) => {
                        return (
                          <Box
                            key={achievement.name + userPending[0]}
                            border={1}
                            style={{
                              borderRadius: "5pt",
                              margin: "5pt",
                              borderColor: "#808080",
                            }}
                          >
                            <Typography style={{ fontSize: "0.7em" }}>
                              {achievement.name +
                                " at " +
                                new Date(
                                  achievement.dateCompleted
                                ).toLocaleString() +
                                ""}
                            </Typography>
                          </Box>
                        );
                      })}
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-around"
                      >
                        <Button
                          variant="contained"
                          onClick={(event) => {
                            event.preventDefault();
                            addAchievementsAdmin(
                              userPending[1],
                              userPending[0]
                            );
                          }}
                        >
                          Confirm
                        </Button>
                        <Button
                          variant="contained"
                          style={{ background: "red" }}
                          onClick={(event) => {
                            event.preventDefault();
                            rejectAchievementsAdmin(userPending[0]);
                          }}
                        >
                          Reject
                        </Button>
                      </Grid>{" "}
                      <Typography
                        style={{
                          fontSize: "0.5em",
                          marginTop: "5pt",
                        }}
                      >
                        {"Crypto Address: " + userPending[0]}
                      </Typography>
                    </Card>
                  </Grid>
                );
              })}
            {/* <Grid
              container
              direction="row"
              style={{ marginTop: "10pt", marginBottom: "10pt" }}
            >
              {users?.length > 0 && (
                <FormControl style={{ width: "50%" }}>
                  <InputLabel
                    id="member-select-label"
                    style={{ color: "white" }}
                  >
                    Username
                  </InputLabel>
                  <Select
                    labelId="member-select-label"
                    id="member-select"
                    value={!selectedUser ? "" : selectedUser.username}
                    defaultValue=""
                    placeholder="Username"
                    label="Username"
                    type="text"
                    //onChange={handleChange}
                    style={{ color: "white" }}
                    className={classes.select}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                        root: classes.root,
                      },
                    }}
                  >
                    {users.map((user, index) => {
                      return (
                        <MenuItem
                          onClick={async (event) => {
                            event.preventDefault();
                            setSelectedUser("");
                            await new Promise((res) => setTimeout(res, 500));
                            setSelectedUser(user);
                            setAchievementsCompleted([]);
                            await new Promise((res) => setTimeout(res, 500));
                          }}
                          key={user.username + index.toString() + "un"}
                          value={user.username}
                        >
                          {user.customUsername
                            ? user.customUsername
                            : user.username}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {users?.length > 0 && (
                <FormControl style={{ width: "50%" }}>
                  <InputLabel
                    id="member-select-label"
                    style={{ color: "white" }}
                  >
                    Member Id
                  </InputLabel>
                  <Select
                    labelId="member-select-label"
                    id="member-select"
                    value={
                      !selectedUser
                        ? ""
                        : selectedUser.membershipId
                        ? selectedUser.membershipId
                        : ""
                    }
                    defaultValue=""
                    placeholder="Member Id"
                    label="Member Id"
                    type="text"
                    //onChange={handleChange}
                    style={{ color: "white" }}
                    className={classes.select}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                        root: classes.root,
                      },
                    }}
                  >
                    {users.map((user, index) => {
                      return (
                        <MenuItem
                          onClick={async (event) => {
                            event.preventDefault();
                            setSelectedUser("");
                            await new Promise((res) => setTimeout(res, 500));
                            setSelectedUser(user);
                            setAchievementsCompleted([]);
                            await new Promise((res) => setTimeout(res, 500));
                          }}
                          key={user.membershipId + index.toString() + "mI"}
                          value={user.membershipId}
                        >
                          {user.membershipId}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid> */}
          </div>
        )}
        {selectedUser !== "" && (
          <div>
            <MemberHistoryView
              user={selectedUser}
              getAchievementHistory={() => {
                getAchievementHistory();
              }}
              achievementsCompleted={achievementsCompleted}
            />
            {/* <MemberManagementView
              user={selectedUser}
              updateAchievements={() => {
                getAchievementHistory();
              }}
            /> */}
            {/*<MemberRedemptionView user={selectedUser} network={network} />
             */}
          </div>
        )}
      </Box>
    </Grid>
  );
}
