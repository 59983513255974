import {
  Grid,
  Container,
  Typography,
  Button,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import React from "react";
import "../App.css";
import { NFTView } from "./NFTView";
import { SectionHeader } from "./SectionHeader";
import METROFLEX from "../assets/NFT_Promp12.png";
import Moralis from "moralis";
import { Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#222",
  color: "white",
  width: "80%",
  boxShadow: 24,
  p: 4,
};
export function MainBody({ redemption, selectedUser, muralStats, reload }) {
  const redeemFor = async (nftId) => {
    const params = {
      muralId: nftId,
    };
    const result = await Moralis.Cloud.run("claimMural", params);
    console.log(result);
    reload();
  };
  console.log(selectedUser);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const handleOpen = (i) => {
    setSelected(i);
    setOpen(true);
  };
  const handleClose = () => {
    setSelected();
    setOpen(false);
  };
  console.log(muralStats);
  return (
    <Container
      style={{
        overflow: "hidden",
        backgroundColor: redemption && "rgba(27,25,24, 0.97)",
        minHeight: "50vh",
        marginBottom: "50pt",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container direction="column" alignItems="center">
            <Typography variant="h6" component="h2">
              Redeem Mural Piece #{selected}?
            </Typography>
            <NFTView
              key={"NFTView" + selected + 1 + "confirmation"}
              index={selected + 1}
              claimable={null}
              claimed={null}
            />
            <Typography sx={{ mt: 2, mb: 2, fontSize: "0.9em" }}>
              <b>{selected === 11 ? "0 points" : "100 points"}</b> will be
              deducted from your account.
            </Typography>
            <Grid container direction="row" justifyContent="space-around">
              <Button
                variant="contained"
                style={{ background: "grey", textTransform: "none" }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              {selected === 11 || selectedUser?.points >= 100 ? (
                <Button
                  variant="contained"
                  style={{ background: "#eb5454", textTransform: "none" }}
                  onClick={() => {
                    redeemFor(selected);
                    handleClose();
                  }}
                >
                  Confirm
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{
                    background: "#eb5454",
                    color: "white",
                    textTransform: "none",
                  }}
                  disabled
                >
                  Not enough points
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {
        //<img src={METROFLEX} width="100%" style={{ maxWidth: "" }} />
      }

      <Grid container direction="row" justifyContent="center">
        <Button
          onClick={
            (redemption && muralStats ? muralStats[1] : false)
              ? () => {
                  handleOpen(0);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView1"}
            index={1}
            claimable={muralStats ? muralStats[1] : false}
            claimed={muralStats ? muralStats[0] : null}
          />
        </Button>
      </Grid>
      <Grid container direction="row" justifyContent="center">
        <Button
          onClick={
            (redemption && muralStats ? muralStats[4] : false)
              ? () => {
                  handleOpen(3);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView4"}
            index={4}
            claimed={muralStats ? muralStats[3] : null}
            claimable={muralStats ? muralStats[4] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[3] : false)
              ? () => {
                  handleOpen(2);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView3"}
            index={3}
            claimed={muralStats ? muralStats[2] : null}
            claimable={muralStats ? muralStats[3] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[2] : false)
              ? () => {
                  handleOpen(1);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView2"}
            index={2}
            claimed={muralStats ? muralStats[1] : null}
            claimable={muralStats ? muralStats[2] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[7] : false)
              ? () => {
                  handleOpen(6);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView7"}
            index={7}
            claimed={muralStats ? muralStats[6] : null}
            claimable={muralStats ? muralStats[7] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[6] : false)
              ? () => {
                  handleOpen(5);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView6"}
            index={6}
            claimed={muralStats ? muralStats[5] : null}
            claimable={muralStats ? muralStats[6] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[5] : false)
              ? () => {
                  handleOpen(4);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView5"}
            index={5}
            claimed={muralStats ? muralStats[4] : null}
            claimable={muralStats ? muralStats[5] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[10] : false)
              ? () => {
                  handleOpen(9);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView10"}
            index={10}
            claimed={muralStats ? muralStats[9] : null}
            claimable={muralStats ? muralStats[10] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[9] : false)
              ? () => {
                  handleOpen(8);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView9"}
            index={9}
            claimed={muralStats ? muralStats[8] : null}
            claimable={muralStats ? muralStats[9] : false}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[8] : false)
              ? () => {
                  handleOpen(7);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView8"}
            index={8}
            claimed={muralStats ? muralStats[7] : null}
            claimable={muralStats ? muralStats[8] : false}
          />
        </Button>
        <Button
          onClick={
            redemption
              ? () => {
                  handleOpen(11);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView12"}
            index={12}
            claimed={muralStats ? muralStats[11] : null}
            claimable={true}
          />
        </Button>
        <Button
          onClick={
            (redemption && muralStats ? muralStats[11] : false)
              ? () => {
                  handleOpen(10);
                }
              : () => {}
          }
        >
          <NFTView
            key={"NFTView11"}
            index={11}
            claimed={muralStats ? muralStats[10] : null}
            claimable={muralStats ? muralStats[11] : false}
          />
        </Button>
      </Grid>
    </Container>
  );
}
