import styled from "@emotion/styled";
import {
  Add,
  AddAPhoto,
  Cancel,
  ChatBubble,
  ChatBubbleOutline,
  ChatBubbleOutlined,
  ChatBubbleOutlineOutlined,
  Close,
  Collections,
  Error,
  Favorite,
  FavoriteBorder,
  Forum,
  ForumOutlined,
  Group,
  Menu,
  PlusOne,
  ThumbUpAlt,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Moralis from "moralis";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { SectionHeader } from "./SectionHeader";
import DefaultAvatar from "../assets/defaultAvatar.png";
import { useFilePicker } from "use-file-picker";
import { Box } from "@mui/system";
import { UserProfileModal } from "./UserProfileModal";
import { ForumDetailTile } from "./ForumDetailTile";
import ReactPlayer from "react-player/lazy";
import { useViewport } from "../viewport";
const CssTextField = styled(OutlinedInput)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    color: "white",
    background: "grey",
    borderColor: "#eb5454",
    borderTopLeftRadius: "5pt",
    borderBottomLeftRadius: "5pt",
    "& fieldset": {
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
    "&:hover fieldset": {
      borderColor: "#eb5454",
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#eb5454",
      color: "white",
      background: "grey",
      borderTopLeftRadius: "5pt",
      borderBottomLeftRadius: "5pt",
    },
  },
});
export function ForumDetailView({ toggleDrawer, count }) {
  let { forumName } = useParams();
  const { width } = useViewport();
  const [newPost, setNewPost] = useState("");
  const [userLoaded, setUserLoaded] = useState(false);
  const [openNewPost, setOpenNewPost] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [feed, setFeed] = useState();
  const [modal, showModal] = useState(false);
  const [selectedUser, setUser] = useState();
  const handleOpen = (user) => {
    setUser(user);
    showModal(true);
  };
  const handleClose = () => showModal(false);

  const handleSubmit = async () => {
    const u = await Moralis.User.currentAsync();
    if (filesContent[0]?.content !== undefined) {
      const moralisFile = new Moralis.File("forumPost", {
        uri: filesContent[0].content,
      });
      moralisFile.save().then(
        async function (value) {
          console.log(value);
          const params = {
            media: value._url,
            topic: forumName,
            text: newPost,
          };
          const res = await Moralis.Cloud.run("addPost", params);
          clear();
          setNewPost("");
          setOpenNewPost(false);
          getForumFeed();
        },
        function (error) {
          console.log(error);
        }
      );
    } else {
      const params = {
        media: undefined,
        topic: forumName,
        text: newPost,
      };
      const res = await Moralis.Cloud.run("addPost", params);
      setNewPost("");
      setOpenNewPost(false);
      getForumFeed();
    }
  };

  const getForumFeed = async () => {
    try {
      const params = { topic: forumName.replace(/_/g, " ") };
      const res = await Moralis.Cloud.run("getForumByTopic", params);
      setFeed(res);
      console.log(JSON.parse(JSON.stringify(res)));
    } catch (err) {
      console.log(err);
    }
  };

  const getUser = async () => {
    getForumFeed();
    const u = await Moralis.User.currentAsync();
    setCurrentUser(JSON.parse(JSON.stringify(u)));
  };
  const [openFileSelector, { filesContent, errors, loading, clear }] =
    useFilePicker({
      readAs: "DataURL",
      accept: ["image/*", "video/*"],
      multiple: false,
      limitFilesConfig: { max: 1 },
      maxFileSize: 50,
    });

  if (!userLoaded) {
    setUserLoaded(true);
    getUser();
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{
        background: "rgba(27,25,24,0.97)",
        minHeight: "100vh",
        height: "100%",
        width: "100vw",
        maxWidth: "700pt",
        paddingBottom: "100pt",
      }}
    >
      <Grid
        item
        width="100%"
        style={{
          paddingTop: "10pt",
          paddingLeft: "10pt",
          maxWidth: "700pt",
          position: "fixed",
          background: "rgba(27,25,24,0.97)",
          zIndex: 2,
        }}
      >
        <Grid
          container
          direction="row"
          justifyItems="flex-start"
          alignItems="center"
          alignContent={"center"}
          wrap="nowrap"
        >
          <Badge badgeContent={count} variant="standard" color="success">
            <IconButton onClick={toggleDrawer(true)}>
              <Menu style={{ color: "white" }} />
            </IconButton>
          </Badge>
        </Grid>
      </Grid>
      <SectionHeader title={forumName.replace(/_/g, " ")} />
      <Grid item width="100%">
        {openNewPost ? (
          <Grid container direction="row" justifyContent="center" wrap="nowrap">
            <FormControl
              sx={{ m: 0, color: "white", borderColor: "#eb5454" }}
              variant="outlined"
            >
              {/* <InputLabel style={{ color: "white" }} >New Post</InputLabel> */}
              <CssTextField
                value={newPost}
                onChange={(event) => {
                  event.preventDefault();
                  setNewPost(event.target.value);
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  setNewPost(event.target.value + "\n");
                  console.log(newPost);
                }}
                multiline
                style={{ background: "grey" }}
                autoComplete="off"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={
                          currentUser?.image === "default"
                            ? DefaultAvatar
                            : currentUser?.image
                        }
                        height="30pt"
                        width="30pt"
                        style={{ borderRadius: "30pt" }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      onClick={(event) => {
                        openFileSelector();
                      }}
                    >
                      <AddAPhoto />
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="New Post"
              />
              {filesContent[0]?.content && (
                <Container style={{ background: "grey" }}>
                  <IconButton
                    onClick={clear}
                    style={{
                      position: "absolute",
                      right: 4,
                      background: "black",
                    }}
                  >
                    <Close style={{ color: "white" }} />
                  </IconButton>
                  {ReactPlayer.canPlay({ url: filesContent[0]?.content }) ? (
                    <ReactPlayer
                      url={filesContent[0]?.content}
                      controls={true}
                      muted={true}
                      playing={true}
                    />
                  ) : (
                    <img
                      src={filesContent[0]?.content}
                      width="100%"
                      style={{
                        maxHeight: "200pt",
                        borderRadius: "10pt",
                        objectFit: "cover",
                        marginTop: "5pt",
                      }}
                    />
                  )}
                </Container>
              )}
              {errors.length > 0 && (
                <Container style={{ background: "grey" }}>
                  <Error /> {errors[0].name}
                </Container>
              )}
            </FormControl>
            <Button
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              style={{
                background: "#eb5454",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                textTransform: "none",
              }}
            >
              Post
            </Button>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent={"flex-end"}
            style={{ paddingRight: "20pt" }}
          >
            <Button
              variant="contained"
              onClick={(event) => {
                setOpenNewPost(true);
              }}
              style={{ background: "#eb5454" }}
            >
              New Post
              <Add />
            </Button>
          </Grid>
        )}
      </Grid>
      <UserProfileModal
        selectedUser={selectedUser}
        handleClose={handleClose}
        modal={modal}
      />
      <Grid item style={{ width: "100%", padding: "5pt" }}>
        {feed && feed?.length > 0 ? (
          feed.map((post) => (
            <ForumDetailTile
              key={post.id}
              post={post}
              handleOpen={handleOpen}
              currentUser={currentUser}
              getForumFeed={getForumFeed}
              forumName={forumName}
            />
          ))
        ) : (
          <Typography>No Posts.</Typography>
        )}
      </Grid>
    </Grid>
  );
}
